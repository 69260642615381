import PropTypes from 'prop-types';

import CheckboxInput from './CheckboxInput';
import Complex from './Complex';
import DateInput from './DateInput';
import RadioInput from './RadioInput';
import TextInput from './TextInput';

const getInputComponent = (type, props) => {
  const components = {
    TEXT: <TextInput {...props} key={props.id} />,
    MULTIPLE_CHOICE: <RadioInput {...props} key={props.id} />,
    CHECKBOX: <CheckboxInput {...props} key={props.id} />,
    DATE: <DateInput {...props} key={props.id} />,
    COMPLEX: (
      <Complex
        {...props}
        forms={props.subForms}
        groupId={props.id}
        key={props.id}
        id={props.id}
      />
    ),
  };
  return components[type] || null;
};

const Section = ({ setValue, forms, values, canEditPage }) => (
  <>
    {forms.map((form) =>
      getInputComponent(form.type, {
        ...form,
        setValue,
        values,
        canEditPage,
      }),
    )}
  </>
);

Section.propTypes = {
  setValue: PropTypes.func,
  forms: PropTypes.array,
  values: PropTypes.shape(),
  canEditPage: PropTypes.bool,
};

export default Section;
