import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { arrUniqEl } from 'infrastructure/functions';
import { PatientSelect } from 'infrastructure/classes/select/patient-select';
import { API_PATIENTS_SELECT_OPTIONS } from 'services/dataService/resources';

import useApi from '../use-api';

import type {
  IPaginationMeta,
  IPartialPaginationMeta,
  IPatientSelectPaginatedData,
} from 'infrastructure/interfaces';
import type { LoginStatus } from 'infrastructure/enums/login-status';
import type { RpmStatuses } from 'infrastructure/enums';

export interface IUsePatientMeta {
  search?: string;
  guid?: string;
  clinicGuid?: string;
  doctorGuid?: string;
  loginStatus?: Array<LoginStatus>;
  patientStatus?: Array<RpmStatuses>;
}

type MergedMeta = IUsePatientMeta & IPaginationMeta;

type UsePatient = {
  loading: boolean;
  meta: MergedMeta;
  loadPatients: (meta: MergedMeta) => Promise<PatientSelect[]>;
};

interface IUsePatientSelectProps {
  meta?: IUsePatientMeta & IPartialPaginationMeta;
}

export const usePatientSelect = (
  props?: IUsePatientSelectProps,
): UsePatient => {
  const { meta: metaProps = {} } = props || {};
  const { loading, loadData, isMounted } = useApi();
  const queryClient = useQueryClient();

  const [meta, setMeta] = useState<MergedMeta>({
    page: 1,
    items: 100,
    totalCount: 0,
    ...metaProps,
  });

  const loadPatients = async (filters: MergedMeta) => {
    const queryKey = [
      API_PATIENTS_SELECT_OPTIONS,
      {
        clinicGuid: filters.clinicGuid,
        doctorGuid: filters.doctorGuid,
        patientStatus: filters.patientStatus,
        guid: filters.guid,
        page: filters.page,
        search: filters.search,
      },
    ];

    const data = await queryClient.fetchQuery(
      queryKey,
      () =>
        loadData<
          IPatientSelectPaginatedData,
          Omit<IUsePatientMeta, 'sortField'>
        >(API_PATIENTS_SELECT_OPTIONS, {
          sortField: JSON.stringify(filters.sortField),
          page: filters.page,
          items: filters.items,
          guid: filters.guid,
          search: filters.search,
          clinicGuid: filters.clinicGuid,
          doctorGuid: filters.doctorGuid,
          loginStatus: filters.loginStatus,
          patientStatus: filters.patientStatus,
        }),
      { staleTime: STALE_TIME },
    );

    if (isMounted && data) {
      const newMeta = {
        ...meta,
        ...filters,
        totalCount: data.count,
      };

      setMeta(newMeta);

      return arrUniqEl(data.items, 'guid').map((el) => new PatientSelect(el));
    }

    return [];
  };

  return {
    loading,
    meta,
    loadPatients,
  };
};
