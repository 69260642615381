import React from 'react';
import BaseTableCard from 'components/atoms/base-table-card';
import BaseGrid from 'components/atoms/base-grid';
import { useTranslation } from 'react-i18next';
import BaseTitledCard from 'components/atoms/base-titled-card';
import ThresholdsViewForm from 'components/molecules/thresholds/view-form';
import ClinicLog from 'components/molecules/admin-panel/forms/clinic-log';

import ClinicInstructionsCard from '../instructions-card';
import ClinicHeaderInfo from '../header-info';
import { useClinicViewForm } from './hook';

import type { Thresholds } from 'infrastructure/classes/thresholds';
import type { Clinic } from 'infrastructure/classes/clinic';

interface IProps {
  info: Clinic;
  thresholds: Thresholds;
}

const ClinicViewForm: React.FC<IProps> = (props) => {
  const { info, thresholds } = props;

  const { t } = useTranslation();

  const {
    contactInformation,
    clinicAddress,
    clinicDetails,
    clinicMappings,
    representatives,
  } = useClinicViewForm({ info });

  return (
    <BaseGrid rowGap={40}>
      <ClinicHeaderInfo info={info} />
      <BaseGrid columns={2} columnGap={20}>
        <BaseTableCard
          title={t('labels.contactInformation')}
          items={contactInformation}
        />
        <BaseTableCard title={t('labels.address')} items={clinicAddress} />
      </BaseGrid>
      <BaseTableCard
        title={t('labels.details')}
        columns={2}
        items={clinicDetails}
      />
      <ClinicInstructionsCard clinicGuid={info.guid} />

      <BaseGrid columns={2} columnGap={20}>
        <BaseTableCard
          title={t('labels.clinicMappings')}
          items={clinicMappings}
        />
        <BaseTableCard
          title={t('labels.representatives')}
          items={representatives}
        />
      </BaseGrid>
      <BaseTitledCard title={t('labels.thresholds')}>
        <ThresholdsViewForm
          data={thresholds}
          hasBloodGlucoseType
          hasWeightType
          hasSPO2Type
          hasBloodPressureType
          hasPeakFlowType
          hasSleepMatType
          showBaselineWeight={false}
        />
      </BaseTitledCard>

      <ClinicLog clinicGuid={info.guid} />
    </BaseGrid>
  );
};

export default ClinicViewForm;
