import React, { useEffect, useState } from 'react';
import DragAndDrop from 'components/atoms/dragAndDrop';
import { useDispatch, useSelector } from 'react-redux';
import AttachmentItem from 'components/molecules/attachment/attachmentItem';
import PatientBlock from 'components/molecules/patientBlock';
import { useTranslation } from 'react-i18next';
import { useEdit } from 'infrastructure/providers/edit-provider';
import useUser from 'utils/useUser';

import withLoader from '../withLoader';

import type { RootState } from 'store';

const formKey = 'Attachments';

type Props = {
  patientGuid: string;
};

const Attachments: React.FC<Props> = ({ patientGuid }) => {
  const { t } = useTranslation();

  const { canEdit, setEdit } = useEdit();
  const { iamPatientAcceptance } = useUser();

  const [isEdit, setIsEdit] = useState(false);
  const attachmentsLoading = useSelector(
    (state: RootState) => state.loading.effects.attachments.getAttachments,
  );
  const attachments = useSelector((state: RootState) => state.attachments.data);
  const {
    attachments: {
      getAttachments,
      createAttachment,
      updateAttachment,
      deleteAttachment,
      downloadAttachment,
    },
  } = useDispatch();

  const onEdit = () => {
    setIsEdit(true);
    setEdit(true, formKey);
  };

  const onCancel = () => {
    setIsEdit(false);
    setEdit(false);
  };

  useEffect(() => {
    getAttachments({ patientGuid });
  }, [patientGuid]);

  return (
    <PatientBlock
      title={t('labels.attachments')}
      isEdit={isEdit}
      onEdit={onEdit}
      onCancel={onCancel}
      disabled={!canEdit(formKey) || iamPatientAcceptance}
    >
      <div className="attachment">
        {isEdit && (
          <DragAndDrop
            patientGuid={patientGuid}
            createAttachment={createAttachment}
          />
        )}

        {attachments.length > 0 ? (
          withLoader(
            attachmentsLoading,
            attachments.map(
              (item: {
                guid: string;
                createdAt: string;
                note: string;
                url: string;
                fileName: string;
              }) => (
                <AttachmentItem
                  attachment={item}
                  key={item.guid}
                  patientGuid={patientGuid}
                  updateAttachment={updateAttachment}
                  deleteAttachment={deleteAttachment}
                  downloadAttachment={downloadAttachment}
                  isEdit={isEdit}
                />
              ),
            ),
          )
        ) : (
          <div className="attachment-no-files">
            No uploaded attachments found
          </div>
        )}
      </div>
    </PatientBlock>
  );
};

export default Attachments;
