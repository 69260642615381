import React, { useEffect, useMemo, useRef, useState } from 'react';
import { uniqBy, debounce } from 'lodash';
import { Select, Spin } from 'antd';
import classNames from 'classnames';
import useCheckMount from 'infrastructure/hooks/utils/use-check-mount';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

import type { IPaginationMeta } from 'infrastructure/interfaces';
import type { IBaseSelectBoxOption, IBaseSelectBoxProps } from './types';

interface IBaseAsyncSelectBoxProps extends IBaseSelectBoxProps {
  fetchOptions: (
    search: string,
    meta: IPaginationMeta,
  ) => Promise<IBaseSelectBoxOption[]>;
  debounceTimeout?: number;
  meta: IPaginationMeta;
  canSearch?: boolean;
  onValueLoaded?: (
    items: IBaseSelectBoxOption[],
    selected?: IBaseSelectBoxOption,
  ) => void;
}

const BaseAsyncSelectBox: React.FC<IBaseAsyncSelectBoxProps> = (props) => {
  const { t } = useTranslation();

  const {
    value,
    placeholder = t('controls.select'),
    disabled,
    plaintext,
    readonly,
    invalid,
    onChange,
    className,
    style,
    fetchOptions,
    debounceTimeout = 400,
    meta,
    mode,
    onValueLoaded,
    minWidth,
    maxWidth,
    allowClear,
    canSearch = false,
    dataCy = 'base-async-select-box',
  } = props;

  const { isMounted } = useCheckMount();
  const [searchValue, setSearchValue] = useState('');
  const [firstLoading, setFirstLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [allItems, setAllItems] = useState<IBaseSelectBoxOption[]>([]);
  const [items, setItems] = useState<IBaseSelectBoxOption[]>([]);
  const fetchRef = useRef(0);

  const loadingItemsState: IBaseSelectBoxOption[] = [
    {
      label: firstLoading ? (
        <div className={s.spin}>
          <Spin size="small" />
        </div>
      ) : (
        '-'
      ),
      value,
      disabled: true,
    },
  ];

  const showLoadingItems = !allItems.length && value;

  const selectBoxClassNames = classNames({ [s.readonly]: readonly }, className);

  const debounceFetcher = useMemo(
    () =>
      debounce(
        (
          searchValueParam: string,
          metaParam: IPaginationMeta,
          focus = false,
          loadSingle = false,
        ) => {
          if (!metaParam.guid && focus && allItems.length) {
            setItems(allItems);
            return;
          }
          if (!loadSingle) {
            delete metaParam.guid;
          }
          fetchRef.current += 1;
          const fetchId = fetchRef.current;
          setFetching(true);
          fetchOptions(searchValueParam, metaParam).then((newItems) => {
            if (isMounted && newItems) {
              if (fetchId !== fetchRef.current) return;
              setAllItems((prevItems) =>
                uniqBy([...prevItems, ...newItems], 'value'),
              );
              setItems((prevItems) =>
                searchValue === searchValueParam
                  ? uniqBy([...prevItems, ...newItems], 'value')
                  : newItems,
              );

              if (onValueLoaded) {
                let rec: IBaseSelectBoxOption | undefined;
                if (metaParam?.guid) {
                  rec = newItems.find((el) => el.value === metaParam?.guid);
                }
                onValueLoaded(newItems, rec);
              }
            }
            setFetching(false);
            if (firstLoading) {
              setFirstLoading(false);
            }
          });
        },
        debounceTimeout,
      ),
    [searchValue, fetchOptions, debounceTimeout, allItems.length],
  );

  const onSearch = (searchProp: string) => {
    setSearchValue(searchProp);
    debounceFetcher(searchProp, { ...meta, page: 1 });
  };

  const onFocus = () => {
    setSearchValue('');
    debounceFetcher('', meta, true);
  };

  const onScroll = (event: any) => {
    const { target } = event;
    const scrolled = Math.ceil(target.scrollTop + target.offsetHeight);
    const inTheBottom = scrolled > target.scrollHeight / 2;
    const hasItems = items.length < meta.totalCount;

    if (!fetching && hasItems && inTheBottom) {
      debounceFetcher(searchValue, { ...meta, page: meta.page + 1 });
    }
  };

  useEffect(() => {
    if (value && allItems.length) {
      const rec = allItems.find((el) => el.value === value);
      if (rec) setItems([rec]);
    }
    if (value && !allItems.length) {
      debounceFetcher(
        searchValue,
        { ...meta, page: 1, guid: value },
        false,
        true,
      );
    }
  }, [value]);

  return (
    <div data-cy={dataCy}>
      <Select
        allowClear={allowClear}
        showSearch={canSearch}
        onSearch={canSearch ? onSearch : undefined}
        style={{
          width: '100%',
          minWidth,
          maxWidth,
          ...style,
          height: plaintext ? '100%' : undefined,
        }}
        filterOption={false}
        onFocus={onFocus}
        options={showLoadingItems ? loadingItemsState : items}
        onPopupScroll={onScroll}
        onChange={(val: any, option: any) => {
          onFocus();
          if (onChange) onChange(val ?? null, option);
        }}
        value={value}
        mode={mode}
        disabled={disabled}
        placeholder={placeholder}
        className={selectBoxClassNames}
        variant={plaintext ? 'borderless' : 'outlined'}
        status={invalid ? 'error' : undefined}
        suffixIcon={fetching || disabled || readonly ? null : undefined}
        dropdownRender={(menu) =>
          allItems.length ? (
            <div>
              {menu}
              <Spin spinning={fetching} size="small">
                {fetching && <div className={s['empty-space']} />}
              </Spin>
            </div>
          ) : (
            <Spin size="small" spinning={firstLoading || fetching}>
              {menu}
            </Spin>
          )
        }
        optionRender={(option) => (
          <div data-cy="select-option">{option.label}</div>
        )}
      />
    </div>
  );
};

export const BaseAsyncSelectBoxDisplayName = 'BaseAsyncSelectBox';
BaseAsyncSelectBox.displayName = BaseAsyncSelectBoxDisplayName;

export default BaseAsyncSelectBox;
