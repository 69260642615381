import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import BaseForm from 'components/atoms/base-form';
import BaseFormGroup from 'components/atoms/base-form/group';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseGrid from 'components/atoms/base-grid';
import BaseInput from 'components/atoms/base-input';
import ContactMethodSelectBox from 'components/molecules/contact-methods-select-box';
import BaseDatepicker from 'components/atoms/base-datepicker';
import BaseExpandAnimation from 'components/atoms/base-expand-animation';
import classNames from 'classnames';
import { mergeFieldsValue } from 'infrastructure/functions';
import BaseButton from 'components/atoms/baseButton';
import PlusIcon from 'components/atoms/icons/plus';
import MinusIcon from 'components/atoms/icons/minus';
import BaseRichTextEditor from 'components/atoms/base-rich-text-editor';

import s from './styles.module.scss';
import { clinicInstructionsValidationSchema } from './validation-schema';

import type { Clinic } from 'infrastructure/classes/clinic';
import type { IClinicInstructions } from 'infrastructure/interfaces';
import type {
  IIndependentFormProps,
  IIndependentFormRef,
} from 'infrastructure/interfaces/common/i-independent-form';

const prepareValue = (data?: Clinic): IClinicInstructions => {
  let defaultValue: IClinicInstructions = {
    criticalBusinessHours: null,
    criticalAfterHours: null,
    afterHours: null,
    businessHours: null,
    contactMethod: null,
    contactMethodValue: null,
    notes: '',
    dateNoteLogged: null,
  };
  if (data) {
    defaultValue = mergeFieldsValue(defaultValue, data);
  }

  return defaultValue;
};

const FormDisplayName = 'ClinicInstructionsForm';

interface IProps extends IIndependentFormProps {
  data?: Clinic;
}

const ClinicInstructionsForm = forwardRef<
  IIndependentFormRef<IClinicInstructions>,
  IProps
>((props, ref) => {
  const { data } = props;

  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  const formik = useFormik<IClinicInstructions>({
    initialValues: prepareValue(data),
    onSubmit: () => {},
    validateOnChange: false,
    validationSchema: opened ? clinicInstructionsValidationSchema() : undefined,
  });

  const { values } = formik;

  const groupClassNames = classNames(s.group, {
    [s.opened]: !opened,
  });

  const controlBtn = useMemo(() => {
    return (
      <BaseButton
        dataCy="clinicInstructions-expand-button"
        rounded
        icon={opened ? <MinusIcon /> : <PlusIcon />}
        size="large"
      />
    );
  }, [opened]);

  const onToggle = () => {
    setOpened((prev) => !prev);
  };

  const submitForm = async () => {
    await formik.submitForm();
    const isValid = await formik
      .validateForm()
      .then((res) => !Object.keys(res).length);

    return {
      values: opened ? formik.values : prepareValue(),
      formName: FormDisplayName,
      isValid: opened ? isValid : true,
    };
  };

  useImperativeHandle(ref, () => ({
    submit: submitForm,
    reset: formik.resetForm,
  }));

  useEffect(() => {
    const val = prepareValue(data);
    const objCopy = {
      ...val,
      notes: val.notes === '' ? undefined : val.notes,
      dateNoteLogged: undefined,
    };
    const hasValue = Object.values(objCopy).some((el) => Boolean(el));
    setOpened(hasValue);
    if (data) formik.resetForm({ values: val });
  }, [data]);

  return (
    <BaseForm formik={formik}>
      <BaseFormGroup
        label={t('labels.clinicInstructions')}
        className={groupClassNames}
        contentClassName={s.content}
        onHeaderClick={onToggle}
        controls={controlBtn}
      >
        <BaseExpandAnimation opened={opened}>
          <BaseGrid columns={2} columnGap={0}>
            <div className={s.col}>
              <BaseGrid rowGap={12}>
                <BaseFormItem
                  name="criticalBusinessHours"
                  label={t('labels.criticalBusinessHours')}
                  isBlackLabel
                >
                  <BaseRichTextEditor
                    value={values.criticalBusinessHours ?? ''}
                    setValue={(value) => {
                      formik.setFieldValue('criticalBusinessHours', value);
                    }}
                    disabled={false}
                  />
                </BaseFormItem>
                <BaseFormItem
                  name="criticalAfterHours"
                  label={t('labels.criticalAfterHours')}
                  isBlackLabel
                >
                  <BaseRichTextEditor
                    value={values.criticalAfterHours ?? ''}
                    setValue={(value) => {
                      formik.setFieldValue('criticalAfterHours', value);
                    }}
                    disabled={false}
                  />
                </BaseFormItem>
              </BaseGrid>
            </div>
            <div className={s.col}>
              <BaseGrid columns={2} columnGap={32}>
                <BaseFormItem
                  name="businessHours"
                  label={t('labels.businessHours')}
                  isBlackLabel
                >
                  <BaseInput />
                </BaseFormItem>
                <BaseFormItem
                  name="afterHours"
                  label={t('labels.afterHours')}
                  isBlackLabel
                >
                  <BaseInput />
                </BaseFormItem>
                <BaseFormItem
                  name="contactMethod"
                  label={t('labels.contactMethod')}
                  isBlackLabel
                >
                  <ContactMethodSelectBox />
                </BaseFormItem>
                <BaseFormItem
                  name="contactMethodValue"
                  label={t('labels.contactMethodValue')}
                  isBlackLabel
                >
                  <BaseInput />
                </BaseFormItem>
                <BaseFormItem
                  name="dateNoteLogged"
                  label={t('labels.dateNote')}
                  isBlackLabel
                  required
                >
                  <BaseDatepicker
                    onChange={(_, date) => {
                      formik.setFieldValue('dateNoteLogged', date || undefined);
                    }}
                  />
                </BaseFormItem>
              </BaseGrid>
              <BaseFormItem
                name="notes"
                label={t('labels.note')}
                required
                isBlackLabel
              >
                <BaseRichTextEditor
                  value={values.notes ?? ''}
                  setValue={(value) => {
                    formik.setFieldValue('notes', value);
                  }}
                  disabled={false}
                />
              </BaseFormItem>
            </div>
          </BaseGrid>
        </BaseExpandAnimation>
      </BaseFormGroup>
    </BaseForm>
  );
});

ClinicInstructionsForm.displayName = FormDisplayName;

export default ClinicInstructionsForm;
