import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

import type { FC, ReactElement } from 'react';

interface PatientServiceRowProps {
  label: string;
  value?: string | ReactElement;
}

const PatientServiceRow: FC<PatientServiceRowProps> = ({ label, value }) => {
  const { t } = useTranslation();
  const dataCy = label.toLowerCase().split(' ').join('-');

  return (
    <div className={s.item}>
      <p className={s.label} data-cy={`${dataCy}-label`}>
        {label}:
      </p>
      <p className={s.value} data-cy={`${dataCy}-value`}>
        {value || t('labels.bannerNoData')}
      </p>
    </div>
  );
};
export default PatientServiceRow;
