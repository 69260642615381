import dataService from 'services/dataService';
import { ACTIVITY_CSV_REPORT } from 'services/dataService/resources';
import { useMutation } from '@tanstack/react-query';
import { showResult } from 'infrastructure/hooks/utils';

import type { TActivityReportFilter } from './use-get-activity-report';

const prepareFilters = (filters?: TActivityReportFilter) => {
  if (!filters) return {};
  const copiedFilters = structuredClone(filters);

  if (copiedFilters.doctorGuid?.length) {
    copiedFilters.doctorGuid = copiedFilters.doctorGuid?.join(',') as any;
  } else {
    delete copiedFilters.doctorGuid;
  }

  if (!copiedFilters.clinicGuid) {
    delete copiedFilters.childClinic;
  }

  return copiedFilters;
};

export const useActivitySendReport = (filters: TActivityReportFilter) => {
  const postData = {
    startTime: filters.startTime,
    endTime: filters.endTime,
    status: filters.status || undefined,
    clinicGuid: filters.clinicGuid,
    patientGuid: filters.patientGuid,
    doctorGuid: filters.doctorGuid,
    insuranceType: filters.insuranceType || undefined,
    reverse: filters.reverse,
    childClinic: filters.childClinic,
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      const preparedPostData = prepareFilters(postData);
      const { error, data } = await dataService.createOne(
        ACTIVITY_CSV_REPORT,
        preparedPostData,
      );
      if (error) {
        showResult(error);
        return;
      }
      return data;
    },
  });

  return {
    isLoading,
    sendReportToEmail: mutate,
  };
};
