import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import Charts from 'components/templates/rpm/patients/patient/charts';
import {
  isAdmin,
  isAgencyDoctor,
  isOnboardingPatient,
  isPatient,
} from 'utils/userTypeHelper';
import useUser from 'utils/useUser';
import {
  onboardingStatuses,
  patientPageStatuses,
} from 'constants/patient_statuses';
import NotFound from 'components/atoms/notFound';
import { isMobile } from 'utils/deviceHelper';
import PatientTopBanner from 'components/molecules/patientTopBanner';
import AdminPatientKeyNotes from 'components/organisms/adminPanelKeyNotes';
import AdminPanelPatientDetailTabs from 'components/organisms/admin-panel/patient-detail/tabbed-forms';
import GeneralComments from 'components/molecules/general-comments';
import { ModuleType } from 'infrastructure/enums';
import ClinicalActivityAndInterventions from 'components/organisms/admin-panel/patient-detail/clinical-activity-and-interventions';
import {
  useRpmPatientDetails,
  useRpmPatientCharts,
} from 'infrastructure/hooks/rpm';
import PatientControlPanel from 'components/organisms/patient-control-panel';
import InteractiveTimeNew from 'components/organisms/interactiveTime/new';
import paths from 'constants/paths';
import { useTranslation } from 'react-i18next';
import { useWebsocketRoom } from 'infrastructure/hooks/websockets/use-room';
import { BroadcastTopics } from 'services/wsService';
import { useUpdateInteractiveTime } from 'components/organisms/interactiveTime/hooks';
import PatientActivity from 'components/organisms/patient-activity';
import MobileMiniNav from 'components/molecules/mini-nav/mobile-mini-nav';
import MiniNav from 'components/molecules/mini-nav';
import PatientMedication from 'components/organisms/patient-medication';
import PatientAverages from 'components/organisms/patient-averages';
import { useQueryUpdatePatientStatus } from 'infrastructure/hooks/patient/use-query-update-patient-status';
import RpmCcmNavigationHeader from 'components/organisms/rpm-ccm-navigation-header';
import { isValidGuid } from 'infrastructure/functions/is-valid-guid';
import { showResult } from 'infrastructure/hooks/utils';

import s from './styles.module.scss';

const PatientPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { patientGuid } = useParams();
  useEffect(() => {
    if (!patientGuid || !isValidGuid(patientGuid)) {
      showResult(t('errors.invalidGuid'));
      navigate('/404', { replace: true });
    }
  }, [patientGuid, navigate]);
  if (!patientGuid || !isValidGuid(patientGuid)) {
    return null;
  }
  const { userType } = useUser();
  const deviceType = useSelector((state) => state.user.deviceType);
  const {
    query: { patient, isLoading },
    clinicGuid,
  } = useRpmPatientDetails({ patientGuid });
  const { charts, isLoading: isChartsLoading } = useRpmPatientCharts({
    patientGuid,
  });

  const { updateInteractionTime } = useUpdateInteractiveTime({
    module: ModuleType.RPM,
    patientGuid,
  });
  const { updateQueryPatientStatus } = useQueryUpdatePatientStatus({
    patientGuid,
  });

  const isNotPatient = !isPatient(userType);
  const dateRange = useSelector((state) => state.sms.dates);
  const socket = useSelector((state) => state.socket);

  const {
    patient: { resetPatient },
  } = useDispatch();

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      resetPatient();
    };
  }, []);

  useWebsocketRoom('rpmPatientProfile', patientGuid);
  useEffect(() => {
    datadogRum.startSessionReplayRecording({ force: true });
    if (socket.isConnected === true && socket.client) {
      socket.client.on(BroadcastTopics.InteractiveTimeInsert, (data) => {
        updateInteractionTime(data);
      });

      socket.client.on(BroadcastTopics.PatientStatusChange, (data) => {
        updateQueryPatientStatus(data);

        if (!patientPageStatuses.includes(data.status)) {
          window.location.reload();
        }
      });
    }

    return () => {
      socket.client?.off(BroadcastTopics.InteractiveTimeInsert);
      socket.client?.off(BroadcastTopics.PatientStatusChange);
      datadogRum.stopSessionReplayRecording();
    };
  }, [socket]);

  if (
    isOnboardingPatient(onboardingStatuses, patient?.profile?.status) ||
    patient?.profile?.status === 'referral_withdrawn'
  ) {
    return (
      <div>
        <NotFound />
      </div>
    );
  }

  return (
    <div className={s.wrapper}>
      <RpmCcmNavigationHeader
        patientGuid={patientGuid}
        clinicGuid={clinicGuid}
        module={ModuleType.RPM}
        goBackPath={paths.dashboard}
        title={t('labels.rpmPatientProfileTitle')}
      />
      <MiniNav charts={charts ?? {}} module={ModuleType.RPM} />
      <MobileMiniNav charts={charts} module={ModuleType.RPM} />
      {!isMobile(deviceType) &&
        (isAdmin(userType) || isAgencyDoctor(userType)) && (
          <AdminPatientKeyNotes
            patientGuid={patientGuid}
            canChangeModule={false}
          />
        )}
      <div className={s.body}>
        <div className={s['banner-control-panel-wrapper']}>
          <PatientTopBanner patientGuid={patientGuid} module={ModuleType.RPM} />

          {!isPatient(userType) && (
            <div className={s['control-panel-wrapper']} id="control-panel">
              <PatientControlPanel
                patientGuid={patientGuid}
                module={ModuleType.RPM}
              />
              <InteractiveTimeNew
                patientGuid={patientGuid}
                module={ModuleType.RPM}
              />
            </div>
          )}
        </div>
        <PatientAverages patientGuid={patientGuid} />
        <Charts
          charts={charts}
          patientDetailsLoading={isLoading}
          chartsLoading={isChartsLoading}
          thresholds={patient?.thresholds}
        />
        <div id="activity">
          <PatientActivity patientGuid={patientGuid} />
        </div>
        <PatientMedication patientGuid={patientGuid} module={ModuleType.RPM} />
        {isNotPatient && dateRange.length && (
          <div id="clinical-activity">
            <ClinicalActivityAndInterventions
              patientGuid={patientGuid}
              module={ModuleType.RPM}
              dateRange={dateRange}
            />
          </div>
        )}
        <div id="user-profile">
          <AdminPanelPatientDetailTabs
            patientGuid={patientGuid}
            module={ModuleType.RPM}
          />
        </div>
        {isNotPatient && (
          <div id="general-comments">
            <GeneralComments
              module={ModuleType.RPM}
              patientGuid={patientGuid}
              withSwitcher
              interactionStatus={patient?.profile?.interactionStatus}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientPage;
