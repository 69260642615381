import React, { useMemo } from 'react';
import BaseSelectBox from 'components/atoms/base-select-box';
import { insuranceProvidersDataSource } from 'infrastructure/data-sources/insurance-providers';

import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';
import type { IInsuranceProviderItem } from 'infrastructure/data-sources/insurance-providers';

type Options = IBaseSelectBoxOption<
  IInsuranceProviderItem['id'],
  IInsuranceProviderItem
>[];

interface IInsuranceProviderSelectBoxProps
  extends Omit<IBaseSelectBoxProps, 'options'> {
  withAll?: boolean;
}

const InsuranceProviderSelectBox: React.FC<
  IInsuranceProviderSelectBoxProps
> = ({ withAll, ...props }) => {
  const dataSource = useMemo(
    () => insuranceProvidersDataSource(withAll),
    [withAll],
  );

  const options: Options = useMemo(
    () =>
      dataSource.map((el) => ({
        value: el.id,
        label: el.name,
        record: el,
      })),
    [dataSource],
  );

  return <BaseSelectBox options={options} {...props} />;
};

export default InsuranceProviderSelectBox;
