import React, { useState, useMemo, useEffect } from 'react';
import DraggableModal from 'components/organisms/draggableModal/draggableModal';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import common from 'constants/common';

import forms from '../constants/forms';
import InternalAdmissionForm from '../Form';
import { formatSendData } from '../helpers';

const InternalAdmissionFormModal = ({ isOpen, onToggle, patient }) => {
  const firstStep = 1;
  const [step, setStep] = useState(firstStep);
  const [renderSections, setRenderSections] = useState({});
  const totalSteps = useMemo(
    () => Object.keys(renderSections).length,
    [renderSections],
  );
  useEffect(() => {
    const modalElement = document.querySelector('#draggable-modal');
    modalElement?.scroll({
      top: 0,
    });
  }, [step]);
  const {
    patient: { getDetails },
    adminPanelPatients: { createInternalAdmissionForm, getPatient },
  } = useDispatch();
  const {
    createInternalAdmissionForm: createInternalAdmissionFormSending,
    patient: patientLoading,
  } = useSelector((state) => state.loading.effects.adminPanelPatients);
  const { admissionFormTimeChanged } = useSelector(
    (state) => state.adminPanelPatients,
  );
  const patientGuid = patient.profile.guid;

  const { patientInfo, setPatientInfo } = usePatientInfo({ patientGuid });

  const loadDataAfterSubmit = async () => {
    if (patientInfo) {
      const newPatientInfo = structuredClone(patientInfo);
      newPatientInfo.disabledCreateInternalAdmissionForms = true;
      setPatientInfo(newPatientInfo);
    }
    await getPatient({ patientGuid });
    await getDetails(patientGuid);
  };

  const sendForm = async (data) => {
    const date = format(new Date(data.date), common.dateFormats.birthDate);
    const submitData = formatSendData(
      { ...data, date, patientGuid },
      admissionFormTimeChanged,
    );
    await createInternalAdmissionForm({
      patientGuid,
      data: submitData,
    });
  };

  const onSubmit = async (data) => {
    await sendForm(data);
    onToggle();
    await loadDataAfterSubmit();
  };

  const handleUpdateSections = (isFull) => {
    if (isFull) {
      setRenderSections(forms);
    } else {
      const shortForms = {
        generalInformation: forms.generalInformation,
        careCoordination: forms.careCoordination,
      };
      setRenderSections(shortForms);
    }
  };

  return (
    <DraggableModal
      isOpen={isOpen}
      toggle={onToggle}
      title={`Internal Admission Form ${step}/${totalSteps}`}
      styleName="popup narrative-popup"
    >
      <div>
        {patient?.profile && (
          <InternalAdmissionForm
            forms={renderSections}
            onUpdateForms={handleUpdateSections}
            onSubmit={(data) => onSubmit(data)}
            patient={patient}
            step={step}
            totalSteps={totalSteps}
            loading={createInternalAdmissionFormSending || patientLoading}
            onSetStep={(st) => setStep(st)}
          />
        )}
      </div>
    </DraggableModal>
  );
};
InternalAdmissionFormModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  patient: PropTypes.shape({
    profile: PropTypes.shape({
      guid: PropTypes.string,
      timezone: PropTypes.string,
    }),
  }).isRequired,
};

export default InternalAdmissionFormModal;
