import RiskScoreTag from 'components/atoms/tags/riskScoreTag';
import { useTranslation } from 'react-i18next';
import BaseLoader from 'components/atoms/base-loader';
import EmailUnsubscribedTag from 'components/atoms/tags/EmailUnsubscribedTag';
import DoNotCallTag from 'components/atoms/tags/DoNotCallTag';
import BaseButton from 'components/atoms/baseButton';
import classNames from 'classnames';
import SlaTag from 'components/atoms/tags/SlaTag';
import { parseDateSafe } from 'utils/timeHelper';
import { differenceInCalendarDays, intervalToDuration } from 'date-fns';
import { capitalize } from 'utils';
import CopyIcon from 'components/atoms/icons/copyIcon';
import CopyableText from 'components/atoms/buttons/copyable-text';
import common from 'constants/common';
import PcmTag from 'components/atoms/tags/PcmTag';
import {
  isAdminModule,
  isCcmModule,
  isRpmModule,
} from 'infrastructure/functions';
import { isUndefined } from 'lodash';
import useUser from 'utils/useUser';
import {
  ClinicStatus,
  LoginStatus,
  type ModuleType,
} from 'infrastructure/enums';

import { useActionButtons } from './hooks';
import s from './styles.module.scss';
import { ActionDropdownButton } from './components/action-dropdown-button';
import PatientStatusRow from './components/patient-status-row';
import PatientServiceRow from './components/patient-service-row';
import { useBannerInfo } from './hooks/useBannerInfo';
import PatientMiniTopBanner from '../patientMiniTopBanner';
import MobileBanner from './components/mobile-banner';

import type { FC } from 'react';

interface PatientTopBannerProps {
  patientGuid: string;
  module: ModuleType;
}

const PatientTopBanner: FC<PatientTopBannerProps> = (props) => {
  const { patientGuid, module } = props;

  const { t } = useTranslation();
  const { isPatient } = useUser();

  const {
    birthDate,
    fullName,
    ccmStatus,
    rpmStatus,
    riskScore,
    serviceDeliveryModel,
    providerServiceType,
    isLoading,
    isShowEmailTag,
    sla,
    clinicName,
    clinicStatus,
    physician,
    physicianStatus,
    referralTypes,
    holdingStatus,
    pcmStatus,
    isShowMiniBanner,
    isShowDoNotCallTag,
    disabledCreateInternalAdmissionForms,
    ccmHoldingStatus,
    consumablesArrivingDate,
    isMobileDevice,
  } = useBannerInfo({ patientGuid, module });

  const { actionButtons, actionDropdownButtons, adminActionButtons } =
    useActionButtons({
      patientGuid,
      disabledCreateInternalAdmissionForms,
    });

  const today = new Date();
  const endDate = parseDateSafe(holdingStatus?.endTime);

  const remainingDays = endDate && differenceInCalendarDays(endDate, today);

  const consumablesEndDate = parseDateSafe(consumablesArrivingDate);
  const consumablesRemainingDays =
    consumablesEndDate &&
    intervalToDuration({ start: today, end: consumablesEndDate })?.days;

  const ccmStatusObj = !isUndefined(ccmStatus)
    ? common.ccmStatus[ccmStatus]
    : undefined;
  const rpmStatusObj = !isUndefined(rpmStatus)
    ? common.statusPatients[rpmStatus]
    : undefined;
  const showHoldingStatusBadge =
    holdingStatus?.status !== 'active' &&
    !isUndefined(holdingStatus?.endTime) &&
    !isUndefined(remainingDays);

  const showTemporaryHoldBadge = ccmHoldingStatus && isCcmModule(module);
  const statusColor =
    isAdminModule(module) || isRpmModule(module)
      ? rpmStatusObj?.color
      : ccmStatusObj?.color;

  const showConsumablesBadge =
    !isCcmModule(module) &&
    !isUndefined(consumablesArrivingDate) &&
    !isUndefined(consumablesRemainingDays);

  const clinicNameElement = (
    <>
      {clinicName}
      {(clinicStatus === ClinicStatus.Delinquent ||
        clinicStatus === ClinicStatus.Inactive) && (
        <span className={classNames(s.status)}>
          ({t(`clinicStatuses.${clinicStatus}`)})
        </span>
      )}
    </>
  );

  const providerNameElement = (
    <>
      {physician}
      {(physicianStatus === LoginStatus.Inactive ||
        physicianStatus === LoginStatus.Blocked) && (
        <span className={classNames(s.status)}>
          ({t(`loginStatuses.${physicianStatus}`)})
        </span>
      )}
    </>
  );

  return (
    <>
      {!isMobileDevice && (
        <div
          data-cy="patient-header"
          className={classNames(s.wrapper, {
            [s.corner]: showHoldingStatusBadge || showTemporaryHoldBadge,
          })}
          id="patient-banner"
        >
          {isLoading ? (
            <div className={s['loading-box']}>
              <BaseLoader loading={isLoading} />
            </div>
          ) : (
            <>
              <div
                className={s['status-indicator']}
                style={{ backgroundColor: statusColor }}
              />
              {showTemporaryHoldBadge && !isPatient && (
                <div
                  data-cy="temporary-hold-tag"
                  className={classNames(s['holding-status'], {
                    [s.corner]: true,
                  })}
                >
                  <p>{t('labels.temporaryHold')}</p>
                </div>
              )}
              {showHoldingStatusBadge && !isPatient && (
                <div
                  data-cy="holding-status-tag"
                  className={classNames(s['holding-status'], {
                    [s.corner]: !showTemporaryHoldBadge,
                    [s['move-right']]: showTemporaryHoldBadge,
                  })}
                >
                  <p>{capitalize(holdingStatus.status)}:</p>
                  <p>
                    {remainingDays + 1}{' '}
                    {remainingDays < 1 ? t('labels.day') : t('labels.days')}
                  </p>
                </div>
              )}
              {showConsumablesBadge && !isPatient && (
                <div
                  data-cy="consumables-date-tag"
                  className={classNames(s['holding-status'], {
                    [s.corner]: !remainingDays,
                    [s['put-space-for-consumables-badge']]: remainingDays,
                    [s['put-space-for-long-status']]:
                      remainingDays && remainingDays > 8,
                  })}
                >
                  <p>{t('labels.awaitingConsumables')}:</p>
                  <p>
                    {consumablesRemainingDays + 1}{' '}
                    {consumablesRemainingDays < 1
                      ? t('labels.day')
                      : t('labels.days')}
                  </p>
                </div>
              )}
              <div className={s['wrapper-left']}>
                <div className={s['title-wrapper']}>
                  <div>
                    <h4 className={s.title} data-cy="patient-name">
                      {fullName}
                    </h4>
                    {!isPatient && (
                      <div className={s['tags-wrapper']}>
                        {!isUndefined(sla) && <SlaTag hours={sla} small />}
                        {isShowDoNotCallTag && <DoNotCallTag small />}
                        {isShowEmailTag && <EmailUnsubscribedTag small />}
                        {pcmStatus && <PcmTag small />}
                      </div>
                    )}
                  </div>
                  <p className={s.date} data-cy="patient-birthday-date">
                    {birthDate}
                  </p>
                  <p data-cy="patient-id" className={s['patient-id']}>
                    <span>{t('labels.patientID')}:</span>{' '}
                    <CopyableText value={patientGuid} icon={<CopyIcon />} />
                  </p>
                </div>
                <div className={s.services}>
                  <PatientServiceRow
                    label={t('labels.providerServiceType')}
                    value={providerServiceType}
                  />
                  <PatientServiceRow
                    label={t('labels.serviceDeliveryModel')}
                    value={serviceDeliveryModel}
                  />
                  <div className={s['services-item']}>
                    <PatientServiceRow
                      label={t('labels.bannerClinic')}
                      value={clinicNameElement}
                    />
                    <div className={s['services-list-divider']} />
                    <PatientServiceRow
                      label={t('labels.bannerProvider')}
                      value={providerNameElement}
                    />
                  </div>
                  <PatientServiceRow
                    label={t('labels.bannerReferralType')}
                    value={referralTypes}
                  />
                </div>
              </div>
              <div className={s['wrapper-right']}>
                <div
                  className={classNames(s.status, {
                    [s.reverse]: isCcmModule(module),
                  })}
                >
                  <PatientStatusRow
                    isActiveLabel={isRpmModule(module)}
                    isActiveValue={isRpmModule(module) || isAdminModule(module)}
                    label={t('labels.rpmStatus')}
                    value={rpmStatusObj?.label ?? 'Invalid Status'}
                    color={rpmStatusObj?.color}
                  />
                  <PatientStatusRow
                    isActiveLabel={isCcmModule(module)}
                    isActiveValue={isCcmModule(module) || isAdminModule(module)}
                    label={t('labels.ccmStatus')}
                    value={ccmStatusObj?.label ?? 'Invalid Status'}
                    color={ccmStatusObj?.color}
                  />
                </div>
                {!isUndefined(riskScore) && isCcmModule(module) && (
                  <RiskScoreTag score={riskScore} />
                )}
                <div className={s['buttons-wrapper']}>
                  {isCcmModule(module) &&
                    actionButtons.map((btn) => (
                      <BaseButton
                        key={btn.key}
                        label={btn.label}
                        icon={<btn.Icon />}
                        onClick={btn.handleClick}
                        loading={btn.loading}
                        type="secondary"
                        dataCy={btn.dataCy}
                      />
                    ))}
                  {isRpmModule(module) &&
                    actionDropdownButtons.map((b) => (
                      <ActionDropdownButton
                        key={b.key}
                        options={b.options}
                        label={b.label}
                        dataCy={b.dataCy}
                        loading={b.loading}
                      />
                    ))}
                  {isAdminModule(module) &&
                    adminActionButtons.map((btn) => (
                      <BaseButton
                        key={btn.key}
                        label={btn.label}
                        icon={<btn.Icon />}
                        onClick={btn.handleClick}
                        loading={btn.loading}
                        type="secondary"
                        dataCy={btn.dataCy}
                        className={s['admin-action-btn']}
                        disabled={btn.disabled}
                      />
                    ))}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {isShowMiniBanner && (
        <PatientMiniTopBanner
          fullName={fullName}
          riskScore={riskScore}
          clinicName={clinicName}
          clinicStatus={clinicStatus}
          doNotCall={isShowDoNotCallTag}
          showEmailTag={isShowEmailTag}
          physician={physician}
          physicianStatus={physicianStatus}
          sla={sla}
          pcmStatus={pcmStatus}
          statusColor={statusColor}
          module={module}
        />
      )}
      {isMobileDevice && (
        <MobileBanner
          patientGuid={patientGuid}
          module={module}
          showHoldingStatusBadge={showHoldingStatusBadge}
          showTemporaryHoldBadge={showTemporaryHoldBadge}
          isLoading={isLoading}
          birthDate={birthDate}
          statusColor={statusColor}
          holdingStatus={holdingStatus}
          remainingDays={remainingDays ?? 0}
          isShowDoNotCallTag={isShowDoNotCallTag}
          isShowEmailTag={isShowEmailTag}
          serviceDeliveryModel={serviceDeliveryModel}
          providerServiceType={providerServiceType}
          pcmStatus={pcmStatus}
          sla={sla}
          fullName={fullName}
          clinicName={clinicName}
          physician={physician}
          referralTypes={referralTypes}
          rpmStatusObj={rpmStatusObj}
          ccmStatusObj={ccmStatusObj}
          riskScore={riskScore}
          disabledCreateInternalAdmissionForms={
            disabledCreateInternalAdmissionForms
          }
        />
      )}
    </>
  );
};
export default PatientTopBanner;
