import { useMutation } from '@tanstack/react-query';
import common from 'constants/common';
import dataService from 'services/dataService';
import { apiPatientVitalsReport } from 'services/dataService/resources';
import { useAppSelector } from 'store';
import { downloadPDF, generateReportName } from 'utils';
import { showResult } from 'infrastructure/hooks/utils';
import useUser from 'utils/useUser';

interface Props {
  patientGuid: string;
  type: 'email' | null;
}

const convertTimeToUtc = (time: number, offset: number): number => {
  return time - offset * 60 * 1000; // subtract offset milliseconds
};

export const useVitalsReport = () => {
  const { isPatient } = useUser();
  const [startTime, endTime] = useAppSelector<[number, number]>(
    (state) => state.patient.dates,
  );
  const startTimeTZOffset = new Date(startTime).getTimezoneOffset();
  const endTimeTZOffset = new Date(endTime).getTimezoneOffset();

  const { mutate, isLoading } = useMutation({
    mutationFn: async ({ patientGuid, type }: Props) => {
      let url = 'reports/reports/vitals';
      if (isPatient) {
        url = 'reports/reports/vitals-lite';
      }
      const { error, data } = await dataService.getOnly(
        apiPatientVitalsReport(
          patientGuid,
          type,
          convertTimeToUtc(startTime, startTimeTZOffset),
          convertTimeToUtc(endTime, endTimeTZOffset),
          null,
          url,
        ),
      );

      if (error) {
        showResult(error);
        throw new Error(error);
      }

      if (type === 'email') {
        return showResult(error, common.submitSuccessTypes.sendReport);
      }

      if (!error && data) {
        downloadPDF(
          data,
          generateReportName(
            `vitals-report-${patientGuid}`,
            [startTime, endTime],
            'pdf',
          ),
        );
      }

      return data;
    },
  });

  return { vitalsReportLoading: isLoading, getVitalsReport: mutate };
};
