import React, { useMemo } from 'react';
import BaseSelectBox from 'components/atoms/base-select-box';
import {
  readingStatusesDataSource,
  type IReadingStatusItem,
} from 'infrastructure/data-sources/reading-statuses';

import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';

type Options = IBaseSelectBoxOption<
  IReadingStatusItem['id'],
  IReadingStatusItem
>[];

interface IReadingStatusSelectBoxProps
  extends Omit<IBaseSelectBoxProps, 'options'> {
  withAll?: boolean;
}

const ReadingStatusSelectBox: React.FC<IReadingStatusSelectBoxProps> = ({
  withAll,
  ...props
}) => {
  const dataSource = useMemo(
    () => readingStatusesDataSource(withAll),
    [withAll],
  );

  const options: Options = useMemo(
    () =>
      dataSource.map((el) => ({
        value: el.id,
        label: el.name,
        record: el,
      })),
    [dataSource],
  );

  return <BaseSelectBox options={options} {...props} />;
};

export default ReadingStatusSelectBox;
