import { isSlaReadingsAllowed } from 'constants/permissions';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { useMemo } from 'react';
import { fullNamePresenter } from 'utils';
import useScrollPosition from 'utils/hooks/shared/useScrollPosition';
import useUser from 'utils/useUser';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';
import { useQuestions } from 'infrastructure/hooks/ccm';
import { useDoctorInfo } from 'infrastructure/hooks/doctor/use-doctor-info';
import { useClinicInfo } from 'infrastructure/hooks/clinic/use-clinic-info';

import type { ModuleType } from 'infrastructure/enums';

interface Props {
  patientGuid: string;
  module: ModuleType;
}

export const useBannerInfo = ({ patientGuid, module }: Props) => {
  const { patientInfo, loading } = usePatientInfo({
    patientGuid,
  });
  const { clinicInfo } = useClinicInfo({
    clinicGuid: patientInfo?.clinicGuid ?? '',
  });
  const { doctorInfo, doctorInfoLoading } = useDoctorInfo({
    doctorGuid: patientInfo?.doctorGuid,
  });

  const pcmStatus = patientInfo?.pcmStatus;
  const { userType, isMobileDevice } = useUser();
  const slaAllowed = isSlaReadingsAllowed(userType);
  const scrollPosition = useScrollPosition();
  const { questions, isFetching: questionLoading } = useQuestions({
    patientGuid,
    module,
  });
  const riskScore = questions?.score ?? 0;

  const isShowDoNotCallTag = patientInfo?.doNotCall === true;
  const birthDate = DateFormatter({
    date: patientInfo?.loginInfo?.birthDate,
    format: DateFormats['MM.dd.yyyy'],
  });
  const fullName = useMemo(
    () =>
      fullNamePresenter({
        firstName: patientInfo?.loginInfo?.firstName,
        middleName: patientInfo?.loginInfo?.middleName,
        lastName: patientInfo?.loginInfo?.lastName,
        title: patientInfo?.loginInfo?.title,
      }),
    [patientInfo?.loginInfo],
  );
  const physician = useMemo(
    () =>
      fullNamePresenter({
        firstName: doctorInfo?.firstName,
        middleName: doctorInfo?.middleName,
        lastName: doctorInfo?.lastName,
        title: doctorInfo?.title,
      }),
    [doctorInfo],
  );
  const physicianStatus = doctorInfo?.status;
  const providerServiceType = clinicInfo?.providerServiceType
    ? clinicInfo.providerServiceType.join('; ')
    : '';
  const sla =
    (slaAllowed && clinicInfo?.serviceDeliveryThresholds) || undefined;
  const rpmStatus = patientInfo?.status;
  const ccmStatus = patientInfo?.ccmStatus;
  const referralTypes = clinicInfo?.referralTypes
    ? clinicInfo.referralTypes.join('; ')
    : '';
  const clinicName = clinicInfo?.legalname ?? '';
  const clinicStatus = clinicInfo?.status ?? '';
  const isShowEmailTag = patientInfo?.emailSubscription === false;
  const isShowMiniBanner = scrollPosition > 260;
  const serviceDeliveryModel = clinicInfo?.serviceDeliveryModel ?? '';
  const holdingStatus = patientInfo?.holdingStatus;
  const disabledCreateInternalAdmissionForms =
    patientInfo?.disabledCreateInternalAdmissionForms;
  const ccmHoldingStatus = patientInfo?.ccmHoldingStatus;
  const consumablesArrivingDate = patientInfo?.consumablesArrivingDate;
  const consumablesOrdered = !!consumablesArrivingDate;

  return {
    birthDate,
    fullName,
    ccmStatus,
    rpmStatus,
    riskScore,
    serviceDeliveryModel,
    providerServiceType,
    isLoading: loading || doctorInfoLoading || questionLoading,
    isShowEmailTag,
    sla,
    clinicName,
    clinicStatus,
    physician,
    physicianStatus,
    referralTypes,
    holdingStatus,
    pcmStatus,
    rpmSmsSubscription: undefined,
    ccmSmsSubscription: undefined,
    isShowMiniBanner,
    isShowDoNotCallTag,
    disabledCreateInternalAdmissionForms,
    ccmHoldingStatus,
    consumablesArrivingDate,
    consumablesOrdered,
    isMobileDevice,
  };
};
