import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  conversationTabs,
  registeredConversationTabs,
} from 'constants/conversationTabs';
import { capitalize, upperCase } from 'utils';
import { isAdmin, isSmsDashboardUnregisteredRole } from 'utils/userTypeHelper';
import cn from 'classnames';
import useUser from 'utils/useUser';
import { useSmsDashboardUnreadCount } from 'infrastructure/hooks/smsDashboard/useUnreadConversationsCount';

import type { RootDispatch, RootState } from 'store';

interface Props {
  setSelectMode: (state: boolean) => void;
  setCheckedConversations: (state: any) => void;
}

const ConversationTabs: React.FC<Props> = ({
  setSelectMode,
  setCheckedConversations,
}) => {
  const { roleNames, userType, platformMode } = useUser();

  const { conversationType, conversationTypes, newMessageBadge } = useSelector(
    (state: RootState) => state.smsDashboard,
  );
  const {
    smsDashboard: { reset, setConversationType },
    user: { switchPlatformMode },
  } = useDispatch<RootDispatch>();

  const handleSwitchPlatform = (mode: string) => {
    if (platformMode === mode) return;

    switchPlatformMode({});
  };
  const { unreadConversationsCount, fetchUnreadConversationsCount } =
    useSmsDashboardUnreadCount();

  const hasAccessUnregisteredTab =
    isSmsDashboardUnregisteredRole(roleNames) || isAdmin(userType);
  const usersAllowedConversationTabs = hasAccessUnregisteredTab
    ? conversationTabs
    : registeredConversationTabs;

  return (
    <div className="sms-dashboard-tabs">
      {usersAllowedConversationTabs.map((item) => (
        <button
          key={item}
          className={cn('sms-dashboard-tabs__item', {
            active: conversationType === item,
            badge: newMessageBadge[conversationType],
          })}
          onClick={() => {
            reset();
            if (item !== conversationTypes[2]) {
              handleSwitchPlatform(capitalize(item));
            }
            fetchUnreadConversationsCount();
            setConversationType(item);
            setSelectMode(false);
            setCheckedConversations([]);
          }}
        >
          {conversationTypes.length > 1 && item === conversationTypes[2]
            ? capitalize(item)
            : upperCase(item)}

          <span className="unread-message-count">
            (
            {
              unreadConversationsCount?.[
                `${item}TotalUnreadCount` as keyof typeof unreadConversationsCount
              ]
            }
            )
          </span>
        </button>
      ))}
    </div>
  );
};

export default ConversationTabs;
