import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterIcon from 'components/atoms/icons/filterIcon';
import EggIcon from 'components/atoms/icons/eggIcon';
import CollapseAnimation from 'components/atoms/base-animations/collapse';
import BaseButton from 'components/atoms/baseButton';
import classNames from 'classnames';
import useUpdateEffect from 'infrastructure/hooks/utils/use-update-effect';

import s from './styles.module.scss';
import ExpandButton from '../expand-button';

interface IPanelItemProps {
  label: string;
  borderBottom?: boolean;
}

export const ExpandableFilterPanelItem: React.FC<
  React.PropsWithChildren<IPanelItemProps>
> = ({ children, label, borderBottom = false }) => {
  return (
    <div
      className={classNames(s.item, {
        [s['border-bottom']]: borderBottom,
      })}
    >
      <label className={s.label} data-cy={`clinicListFilter-${label}`}>
        <span>{label}</span>
        {children}
      </label>
    </div>
  );
};

interface IProps {
  filtersCount: number;
  children: React.ReactNode;
  title?: string;
  openWithValue?: boolean;
  isExpanded?: boolean;
  onReset?: () => void;
}

const ExpandableFilterPanel: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const {
    title = t('controls.filters'),
    filtersCount,
    onReset,
    children,
    openWithValue = false,
    isExpanded = false,
  } = props;

  const [expanded, setExpanded] = useState(isExpanded);
  const hasFilters = useMemo(() => filtersCount > 0, [filtersCount]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  useUpdateEffect(() => {
    if (openWithValue && hasFilters) {
      setExpanded(true);
    }
  }, [openWithValue, hasFilters]);

  return (
    <div className={s.wrapper}>
      <div
        className={classNames(s.header, {
          [s.opened]: expanded,
        })}
      >
        <div className={s.title} onClick={toggleExpanded}>
          {hasFilters ? <EggIcon count={filtersCount} /> : <FilterIcon />}
          <h4>{title}</h4>
        </div>
        <div className={s.controls}>
          {hasFilters && onReset && (
            <BaseButton
              dataCy="resetFilters-button"
              width="130px"
              type="secondary"
              label={t('controls.resetFilters')}
              onClick={onReset}
              className={s['reset-btn']}
            />
          )}
          <ExpandButton
            expanded={expanded}
            onClick={toggleExpanded}
            dataCy="toggle-filters-btn"
          />
        </div>
      </div>
      <CollapseAnimation opened={expanded}>{children}</CollapseAnimation>
    </div>
  );
};

export default ExpandableFilterPanel;
