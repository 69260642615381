import React from 'react';
import PropTypes from 'prop-types';

import TextInput from './TextInput';
import SelectInput from './SelectInput';
import DatePickerInput from './DatePickerInput';
import RadioInput from './RadioInput';
import CheckboxInput from './CheckboxInput';
import TimePickerInput from './TimePickerInput';

const FormInput = ({ values, type, params, error }) => {
  let { required } = params;
  const { requiredIf } = params;
  if (requiredIf) {
    required = values[requiredIf.key] === requiredIf.value;
  }
  const components = {
    text: <TextInput {...params} required={required} error={error} />,
    select: <SelectInput {...params} required={required} error={error} />,
    radio: <RadioInput {...params} required={required} error={error} />,
    checkbox: <CheckboxInput {...params} required={required} error={error} />,
    time: <TimePickerInput {...params} required={required} error={error} />,
    date: <DatePickerInput {...params} required={required} error={error} />,
  };
  return <>{components[type] || null}</>;
};
FormInput.propTypes = {
  values: PropTypes.object,
  type: PropTypes.oneOf([
    'text',
    'select',
    'radio',
    'date',
    'radio',
    'checkbox',
    'time',
  ]),
  params: PropTypes.shape({
    id: PropTypes.string,
    required: PropTypes.bool,
    requiredIf: PropTypes.object,
  }),
  error: PropTypes.string,
};

export default FormInput;
