import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseTableReport from 'components/atoms/base-table/report/report';
import { useGetActivityReport } from 'infrastructure/hooks/rpm/activityReport/use-get-activity-report';

import { activityReportTableColumns } from './cols';

import type { IActivityReportListFilters } from 'infrastructure/interfaces';

interface IProps {
  filters: IActivityReportListFilters;
  updateFilter: (newFilter: Partial<IActivityReportListFilters>) => void;
}

const ActivityReportTable: React.FC<IProps> = ({ filters, updateFilter }) => {
  const { t } = useTranslation();

  const { reports, loading, meta } = useGetActivityReport({ meta: filters });

  const columns = activityReportTableColumns();

  const onChangePage = (page: number) => {
    updateFilter({ page });
  };

  return (
    <div>
      <BaseTableReport
        isLoading={loading}
        columns={columns}
        headerTitle={t('labels.readings')}
        dataSource={reports}
        pagination={{
          current: meta.page ?? 0,
          pageSize: meta.items,
          total: meta?.totalCount ?? 0,
          onChange: onChangePage,
        }}
        showCount
        anchor
        rowClassName="activity-row"
      />
    </div>
  );
};

export default ActivityReportTable;
