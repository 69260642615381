import i18next from 'i18next';
import { HTML_ARROW } from 'infrastructure/consts/symbols';

import s from './styles.module.scss';

import type { IBaseTableColumns } from 'components/atoms/base-table/types';
import type { BaseLog } from 'infrastructure/classes/logs/base-log';

export const logsColumns = (): IBaseTableColumns<BaseLog> => {
  return [
    {
      label: i18next.t('labels.dateTime'),
      key: 'date',
      width: '170px',
      render(value) {
        return (
          <span className={s.date} data-cy="logs-dateChanged">
            {value}
          </span>
        );
      },
    },
    {
      label: i18next.t('labels.statusChanging'),
      key: 'from',
      width: '400px',
      responsive: ['md'],
      render(_, record) {
        return (
          <div data-cy="logs-status-change">
            {record.from && (
              <>
                <span className={s.from} data-cy="logs-status-change-from">
                  {`${i18next.t('labels.from')}: ${record.from} `}
                </span>
                {HTML_ARROW}
              </>
            )}
            <span className={s.to} data-cy="logs-status-change-to">
              {` ${i18next.t('labels.to')}: ${record.to}`}
            </span>
          </div>
        );
      },
    },
    {
      label: i18next.t('labels.electronicallySigned'),
      align: 'right',
      key: 'who',
      width: '330px',
      render(value) {
        return (
          <span className={s.who} data-cy="logs-electronicallySigned">
            {value}
          </span>
        );
      },
    },
  ];
};
