import BannerActionMailIcon from 'components/atoms/icons/bannerActionMailIcon';
import BannerActionDownloadIcon from 'components/atoms/icons/bannerActionDownloadIcon';
import { makeid } from 'utils/helpers/makeid';
import useUser from 'utils/useUser';

import { useDownloadReport } from './useDownloadReport';
import { useConsentFormReport } from './useConsentFormReport';
import { useVitalsReport } from './useDownloadVitalsReport';
import { usePlanOfCareReport } from './useDownloadPlanOfCareReport';
import { useGetIafReport } from './useGetIafReport';

interface Props {
  patientGuid: string;
  disabledCreateInternalAdmissionForms?: boolean;
}

export const useActionButtons = ({
  patientGuid,
  disabledCreateInternalAdmissionForms,
}: Props) => {
  const { isPatient } = useUser();
  const { mutate: getEmailReport, isLoading: emailReportLoading } =
    useDownloadReport();
  const { mutate: getDownloadReport, isLoading: downloadReportLoading } =
    useDownloadReport();
  const { sendConsentFormReport, isLoading } = useConsentFormReport({
    patientGuid,
  });
  const { getVitalsReport, vitalsReportLoading } = useVitalsReport();
  const { getPlanOfCareReport, planOfCareReportLoading } =
    usePlanOfCareReport();
  const { getIfaReport, ifaReportLoading } = useGetIafReport();

  const actionButtons = [
    {
      key: makeid(5),
      label: 'Send Email Report',
      Icon: BannerActionMailIcon,
      loading: emailReportLoading,
      dataCy: 'ccm-banner_send-email-report-action-btn',
      handleClick: () =>
        getEmailReport({
          patientGuid,
          type: 'email',
        }),
    },
    {
      key: makeid(5),
      label: 'Care Plan',
      Icon: BannerActionDownloadIcon,
      loading: downloadReportLoading,
      dataCy: 'ccm-banner_care-plan-report-action-btn',
      handleClick: () =>
        getDownloadReport({
          patientGuid,
          type: null,
        }),
    },
    {
      key: makeid(5),
      label: 'Consent Form',
      Icon: BannerActionDownloadIcon,
      handleClick: () => sendConsentFormReport(),
      loading: isLoading,
      dataCy: 'ccm-banner_consent-form-report-action-btn',
    },
  ];

  const actionDropdownButtons = [
    {
      key: makeid(5),
      label: 'Vitals Report',
      dataCy: 'rpm-banner_vitals-report-action-dropdown',
      loading: vitalsReportLoading,
      options: [
        {
          label: 'Send on Email',
          handleClick: () =>
            getVitalsReport({
              patientGuid,
              type: 'email',
            }),
          dataCy: 'rpm-banner_vitals-report-email-dropdown-btn',
        },
        {
          label: 'Download',
          handleClick: () =>
            getVitalsReport({
              patientGuid,
              type: null,
            }),
          dataCy: 'rpm-banner_vitals-report-download-dropdown-btn',
        },
      ],
    },
  ];

  if (!isPatient) {
    actionDropdownButtons.push({
      key: makeid(5),
      label: 'Plan of Care',
      dataCy: 'rpm-banner_plane-of-care-action-dropdown',
      loading: planOfCareReportLoading,
      options: [
        {
          label: 'Send on Email',
          handleClick: () =>
            getPlanOfCareReport({
              patientGuid,
              type: 'email',
            }),
          dataCy: 'rpm-banner_plane-of-care-email-dropdown-btn',
        },
        {
          label: 'Download',
          handleClick: () =>
            getPlanOfCareReport({
              patientGuid,
              type: null,
            }),
          dataCy: 'rpm-banner_plane-of-care-download-dropdown-btn',
        },
      ],
    });
  }

  const adminActionButtons = [
    {
      key: makeid(5),
      label: 'IAF',
      Icon: BannerActionDownloadIcon,
      loading: ifaReportLoading,
      dataCy: 'admin-banner_ifa-report-action-btn',
      handleClick: () => getIfaReport({ patientGuid }),
      disabled: !disabledCreateInternalAdmissionForms,
    },
  ];

  return { actionButtons, actionDropdownButtons, adminActionButtons };
};
