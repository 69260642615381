import React from 'react';
import { useTranslation } from 'react-i18next';

import DownloadRoundedIcon from '../icons/downloadRoundedIcon';

interface IProps {
  disabled?: boolean;
  onClick?: () => void;
}

const BaseDownloadButton: React.FC<IProps> = ({ disabled, onClick }) => {
  const { t } = useTranslation();
  return (
    <button onClick={onClick} disabled={disabled}>
      <DownloadRoundedIcon className="performance-review__header-icon-svg" />
      <br />
      <span>{t('labels.download')}</span>
    </button>
  );
};

export default BaseDownloadButton;
