import { Container } from 'reactstrap';
import ConsentFormContent from 'components/templates/ccm/consentForm';

const ConsentForm = () => (
  <div>
    <Container>
      <ConsentFormContent />
    </Container>
  </div>
);

export default ConsentForm;
