import ActivityIcon from 'components/atoms/icons/activityIcon';
import AllergyIcon from 'components/atoms/icons/allergyIcon';
import AverageIcon from 'components/atoms/icons/averageIcon';
import BannerProfileIcon from 'components/atoms/icons/bannerProfileIcon';
import ClinicalActivityIcon from 'components/atoms/icons/clinicalActivityIcon';
import ControlPanelIcon from 'components/atoms/icons/controlPanelIcon';
import GeneralCommentsIcon from 'components/atoms/icons/generalCommentsIcon';
import GraphsIcon from 'components/atoms/icons/graphsIcon';
import MedicalHistoryIcon from 'components/atoms/icons/medicalHistoryIcon';
import MedicationIcon from 'components/atoms/icons/medicationIcon';
import PatientAppointment from 'components/atoms/icons/patientAppointmentIcon';
import PatientGoalsIcon from 'components/atoms/icons/patientGoalsIcon';
import QuestionnaireIcon from 'components/atoms/icons/questionnaireIcon';
import UserProfileIcon from 'components/atoms/icons/userProfileIcon';
import { ModuleType } from 'infrastructure/enums';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'utils/deviceHelper';
import { makeid } from 'utils/helpers/makeid';
import useUser from 'utils/useUser';
import { isPatient } from 'utils/userTypeHelper';

interface MiniNavProps {
  charts?: Record<string, any[]>;
}

export const useMiniNav = ({ charts = {} }: MiniNavProps) => {
  const { userType, deviceType } = useUser();
  const { t } = useTranslation();
  const hasDiagnosis = Object.entries(charts).some(
    ([, data]) => data?.length > 0,
  );

  const linkToBanner = isMobile(deviceType)
    ? '#patient-banner-mob'
    : '#patient-banner';

  const linksList = {
    [ModuleType.RPM]: [
      {
        key: makeid(5),
        title: t('labels.patientBanner'),
        to: linkToBanner,
        dataCy: 'sidebar-patient-banner',
        icon: <UserProfileIcon />,
        render: true,
      },
      {
        key: makeid(5),
        title: t('labels.controlPanel'),
        to: '#control-panel',
        dataCy: 'sidebar-control-panel',
        icon: <ControlPanelIcon />,
        render: !isPatient(userType),
      },
      {
        key: makeid(5),
        title: t('labels.average'),
        to: '#average-metrics',
        dataCy: 'sidebar-average-metrics',
        icon: <AverageIcon />,
        render: true,
      },
      {
        key: makeid(5),
        title: t('labels.graphs'),
        to: '#graphs',
        dataCy: 'sidebar-graphs',
        icon: <GraphsIcon />,
        render: hasDiagnosis,
      },
      {
        key: makeid(5),
        title: t('labels.activity'),
        to: '#activity',
        dataCy: 'sidebar-activity',
        icon: <ActivityIcon />,
        render: true,
      },
      {
        key: makeid(5),
        title: t('labels.medication'),
        to: '#medication',
        dataCy: 'sidebar-medication',
        icon: <MedicationIcon />,
        render: true,
      },
      {
        key: makeid(5),
        title: t('labels.clinicalActivity'),
        to: '#clinical-activity',
        dataCy: 'sidebar-clinical-activity',
        icon: <ClinicalActivityIcon />,
        render: !isPatient(userType),
      },
      {
        key: makeid(5),
        title: t('labels.patientInfo'),
        to: '#user-profile',
        dataCy: 'sidebar-patient-info',
        icon: <BannerProfileIcon />,
        render: true,
      },
      {
        key: makeid(5),
        title: t('labels.generalComments'),
        to: '#general-comments',
        dataCy: 'sidebar-general-comment',
        icon: <GeneralCommentsIcon />,
        render: !isPatient(userType),
      },
    ].filter(({ render }) => render),
    [ModuleType.CCM]: [
      {
        key: makeid(5),
        title: t('labels.patientBanner'),
        to: linkToBanner,
        dataCy: 'sidebar-patient-banner',
        icon: <UserProfileIcon />,
        render: true,
      },
      {
        key: makeid(5),
        title: t('labels.controlPanel'),
        to: '#control-panel',
        dataCy: 'sidebar-control-panel',
        icon: <ControlPanelIcon />,
        render: true,
      },
      {
        key: makeid(5),
        title: t('labels.appointment'),
        to: '#appointment-details',
        dataCy: 'sidebar-appointment-details',
        icon: <PatientAppointment />,
        render: true,
      },
      {
        key: makeid(5),
        title: t('labels.questionnaire'),
        to: '#questionnaire',
        dataCy: 'sidebar-questionnaire',
        icon: <QuestionnaireIcon />,
        render: true,
      },
      {
        key: makeid(5),
        title: t('labels.medicalHistory'),
        to: '#medical-history',
        dataCy: 'sidebar-medical-history',
        icon: <MedicalHistoryIcon />,
        render: true,
      },
      {
        key: makeid(5),
        title: t('labels.allergyLog'),
        to: '#allergy-history',
        dataCy: 'sidebar-allergy-history',
        icon: <AllergyIcon />,
        render: true,
      },
      {
        key: makeid(5),
        title: t('labels.clinicalActivity'),
        to: '#clinical-activity',
        dataCy: 'sidebar-clinical-activity',
        icon: <ClinicalActivityIcon />,
        render: !isPatient(userType),
      },
      {
        key: makeid(5),
        title: t('labels.medication'),
        to: '#medication',
        dataCy: 'sidebar-medication',
        icon: <MedicationIcon />,
        render: true,
      },
      {
        key: makeid(5),
        title: t('labels.patientGoals'),
        to: '#patient-goals',
        dataCy: 'sidebar-patient-goals',
        icon: <PatientGoalsIcon />,
        render: true,
      },
      {
        key: makeid(5),
        title: t('labels.patientInfo'),
        to: '#user-profile',
        dataCy: 'sidebar-patient-info',
        icon: <BannerProfileIcon />,
        render: true,
      },
      {
        key: makeid(5),
        title: t('labels.generalComments'),
        to: '#general-comments',
        dataCy: 'sidebar-general-comment',
        icon: <GeneralCommentsIcon />,
        render: !isPatient(userType),
      },
    ].filter(({ render }) => render),
    [ModuleType.ADMIN]: [
      {
        key: makeid(5),
        title: t('labels.patientBanner'),
        to: linkToBanner,
        dataCy: 'sidebar-patient-banner',
        icon: <UserProfileIcon />,
        render: true,
      },
      {
        key: makeid(5),
        title: t('labels.controlPanel'),
        to: '#control-panel',
        dataCy: 'sidebar-control-panel',
        icon: <ControlPanelIcon />,
        render: true,
      },
      {
        key: makeid(5),
        title: t('labels.patientInfo'),
        to: '#user-profile',
        dataCy: 'sidebar-patient-info',
        icon: <BannerProfileIcon />,
        render: true,
      },
      {
        key: makeid(5),
        title: t('labels.generalComments'),
        to: '#general-comments',
        dataCy: 'sidebar-general-comment',
        icon: <GeneralCommentsIcon />,
        render: true,
      },
    ].filter(({ render }) => render),
  } as const;

  return { linksList };
};
