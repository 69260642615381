import React from 'react';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import type { IActivityData } from 'infrastructure/interfaces/i-rpm-patient-activity';

interface IProps {
  data: IActivityData;
}

const TimeColumn: React.FC<IProps> = ({ data }) => {
  return (
    <div>
      <div>
        R:{' '}
        {DateFormatter({
          date: new Date(parseInt(data.createdAt)),
          format: DateFormats['eee MMM d, yyyy'],
        })}
      </div>
      <div>
        {DateFormatter({
          date: new Date(parseInt(data.createdAt)),
          format: DateFormats['hh:mm:ss aa'],
        })}
      </div>
    </div>
  );
};

export default TimeColumn;
