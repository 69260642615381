import { useQuery } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { useState, type Dispatch, type SetStateAction } from 'react';
import { prepareLogsDateRange } from 'infrastructure/functions';
import { ClinicLog } from 'infrastructure/classes/logs/clinic-log';

import useApi from '../use-api';

import type { DateRange } from 'infrastructure/types';
import type {
  IPaginatedClinicLogs,
  IPaginationMeta,
  IPartialPaginationMeta,
} from 'infrastructure/interfaces';

const clinicLogsGetApi = (clinicGuid: string) =>
  `admins/admin-panel/clinics/${clinicGuid}/logs`;

export interface IClinicLogMeta {
  dateRange?: DateRange;
}

type mergedMeta = IClinicLogMeta & IPaginationMeta;

type IUseClinicLogs = {
  loading: boolean;
  meta: mergedMeta;
  items: Array<ClinicLog>;
  setMeta: Dispatch<SetStateAction<mergedMeta>>;
};

interface IUseClinicLogsProps {
  clinicGuid: string;
  meta?: IClinicLogMeta & IPartialPaginationMeta;
}

export const useClinicLogs = (props: IUseClinicLogsProps): IUseClinicLogs => {
  const { clinicGuid, meta: metaProps } = props;

  const [meta, setMeta] = useState<mergedMeta>({
    page: 1,
    items: 15,
    totalCount: 0,
    ...metaProps,
  });

  const { loadData } = useApi();

  const queryKey = [
    clinicLogsGetApi(clinicGuid),
    prepareLogsDateRange(meta.dateRange),
    meta.page,
  ];

  const { data = [], isLoading } = useQuery({
    queryKey,
    queryFn: async () =>
      loadData<IPaginatedClinicLogs>(clinicLogsGetApi(clinicGuid), {
        page: meta.page,
        items: meta.items,
        ...prepareLogsDateRange(meta.dateRange),
      }).then((res) => {
        if (res) {
          const { count, data: rows } = res;

          setMeta({
            ...meta,
            totalCount: count,
          });

          return rows.map((el) => new ClinicLog(el));
        }
      }),
    enabled: Boolean(clinicGuid),
    staleTime: STALE_TIME,
  });

  return {
    items: data,
    loading: isLoading,
    meta,
    setMeta,
  };
};
