import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import cn from 'classnames';
import TextArea from 'components/atoms/textarea';
import useUser from 'utils/useUser';
import {
  hasAdminPanelAccess,
  hasClinicalRole,
  hasPatientAcceptanceRole,
} from 'utils/accessManager/helpers';
import { isAdmin, isPractice, isPatientAcceptance } from 'utils/userTypeHelper';
import { useUpdateInteractionStatus } from 'infrastructure/hooks/control-panel';
import { useComment } from 'infrastructure/hooks/use-comment';
import { ModuleType } from 'infrastructure/enums';
import BaseModal from 'components/atoms/base-modal';
import { useTranslation } from 'react-i18next';
import BaseButton from 'components/atoms/baseButton';
import ChatLeftTextIcon from 'components/atoms/icons/chat-left-text';
import useAlert from 'infrastructure/hooks/utils/use-alert';

import s from './styles.module.scss';
import PcctModal from './pcctModal';

const GeneralCommentModal = ({
  isOpen,
  toggle,
  patientGuid,
  loading,
  submit,
  comment,
  resetCommentValue,
  isCcmPanel,
  isRpmPanel,
  isAdminPanel,
  interactionStatus,
  getCommentsList = true,
}) => {
  const { t } = useTranslation();

  const { roleNames, userType } = useUser();

  const { updateInteractionStatus } = useUpdateInteractionStatus();

  const { meta, setMeta, refetch } = useComment({
    patientGuid,
    meta: {
      commentType: ModuleType.RPM,
    },
    enabled: getCommentsList,
  });

  const { Alert, showAlert } = useAlert();

  const showSwitcher = useMemo(() => {
    return (
      hasPatientAcceptanceRole(roleNames) ||
      hasAdminPanelAccess(roleNames) ||
      hasClinicalRole(roleNames) ||
      isAdmin(userType)
    );
  }, [roleNames, userType]);

  const [commentType, setCommentType] = useState('rpm');
  const [localComment, setLocalComment] = useState('');
  const [opened, setOpened] = useState(false);
  const [pcctValues, setPcctValues] = useState({});

  useMemo(() => {
    if (isRpmPanel) setCommentType('rpm');
    if (isCcmPanel) setCommentType('ccm');
  }, [isCcmPanel, isRpmPanel]);

  const handleSubmit = async (values) => {
    const postData = {
      patientGuid,
      type: 'text',
      message: values.comment,
      commentType,
    };
    let interactionConfirm = false;

    if (!isPractice(userType) && !isPatientAcceptance(roleNames)) {
      if (commentType === 'rpm' && !interactionStatus) {
        interactionConfirm = await showAlert({
          title: t('labels.monthlyInteraction'),
          messageTitle: t('confirm.clinicalNoteMonthlyInteraction'),
          successBtnLabel: t('controls.yes'),
          rejectBtnLabel: t('controls.no'),
        });
      }
    }
    toggle();
    const response = await submit(postData).then((res) => {
      setMeta({
        ...meta,
        commentType: postData.commentType,
      });
      if (meta.commentType === postData.commentType) refetch();

      return res;
    });

    if (response && interactionConfirm) {
      updateInteractionStatus({
        patientGuid,
        isInteracted: true,
      });
    }

    if (pcctValues) {
      setPcctValues({});
    }
    resetCommentValue();
  };
  const handleCommentType = () => {
    if (commentType === 'rpm') setCommentType('ccm');
    else setCommentType('rpm');
  };

  return (
    <>
      <BaseModal
        title={t('labels.generalComment')}
        open={isOpen}
        onCancel={() => toggle(false)}
        width="1000px"
        withFooter={[
          <BaseButton
            label={t('controls.cancel')}
            type="secondary"
            onClick={() => toggle(false)}
            key={t('controls.cancel')}
            loading={loading}
          />,
          <BaseButton
            label={t('controls.send')}
            onClick={() => {
              handleSubmit({ comment: localComment });
              setLocalComment('');
            }}
            key={t('controls.send')}
            loading={loading}
            dataCy="send-button"
            disabled={localComment.length === 0}
          />,
        ]}
      >
        <Formik initialValues={{ comment }} onSubmit={handleSubmit}>
          {({ handleChange, values }) => (
            <Form>
              <Field
                onChange={(e) => {
                  handleChange(e);
                  setLocalComment(e.target.value);
                }}
                id="comment"
                name="comment"
                type="comment"
                as={TextArea}
                rows="8"
                value={localComment || values.comment}
                placeholder={t('controls.typeYourComment')}
                className="general-comment-textarea"
              />

              {(isRpmPanel || isAdminPanel) && commentType !== 'ccm' && (
                <BaseButton
                  loading={loading}
                  type="secondary"
                  icon={<ChatLeftTextIcon />}
                  onClick={() => setOpened(true)}
                  className={cn(s['generate-btn'], {
                    [s['is-admin']]: isAdminPanel,
                  })}
                  dataCy="generate-general-comment-button"
                />
              )}
              {showSwitcher && isAdminPanel && (
                <div className="general-comments-comment-type">
                  Comment Type:{' '}
                  <div
                    className="general-comments-switch-button"
                    onClick={handleCommentType}
                  >
                    <span
                      className={cn('general-comments-switch-item', {
                        'general-comments-switch-item-active':
                          commentType === 'rpm',
                      })}
                    >
                      RPM
                    </span>
                    <span
                      className={cn('general-comments-switch-item', {
                        'general-comments-switch-item-active':
                          commentType === 'ccm',
                      })}
                    >
                      CCM
                    </span>
                  </div>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </BaseModal>
      {(isRpmPanel || isAdminPanel) && opened && (
        <PcctModal
          isOpen={opened}
          closeModal={() => setOpened(false)}
          values={pcctValues}
          setValues={setPcctValues}
          setComment={setLocalComment}
        />
      )}
      {Alert}
    </>
  );
};
GeneralCommentModal.defaultProps = {
  isOpen: false,
};

GeneralCommentModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  patientGuid: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  comment: PropTypes.string,
  resetCommentValue: PropTypes.func,
  isCcmPanel: PropTypes.bool,
  isRpmPanel: PropTypes.bool,
  isAdminPanel: PropTypes.bool,
  interactionStatus: PropTypes.bool,
  getCommentsList: PropTypes.bool,
};

export default GeneralCommentModal;
