import UploadFile from 'components/organisms/csvUpload';
import BaseModal from 'components/atoms/base-modal';
import BaseButton from 'components/atoms/baseButton';
import { useTranslation } from 'react-i18next';
import DownloadOutlinedIcon from 'components/atoms/icons/downloadOutlinedIcon';
import { useState, type FC } from 'react';
import DocumentIcon from 'components/atoms/icons/documentIcon';
import TrashDeleteIcon from 'components/atoms/icons/trashIcon';
import SuccessfullyDoneIcon from 'components/atoms/icons/successfullyDoneIcon';
import { Tooltip } from 'antd';
import XIcon from 'components/atoms/icons/xIcon';

interface Props {
  isOpen: boolean;
  toggle: () => any;
  uploadFunction: (file: File) => Promise<{ error?: string }>;
  sampleFileUrl?: string;
  title: string;
  fileType?: 'csv' | 'json';
}

interface MyFile extends File {
  status: 'success' | 'error' | 'pending' | 'default';
  message?: string;
}

const MultiFileUploadModal: FC<Props> = ({
  isOpen,
  toggle,
  uploadFunction,
  sampleFileUrl,
  title,
  fileType = 'csv',
}) => {
  const { t } = useTranslation();
  const [localFiles, setLocalFiles] = useState<MyFile[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);

  const clearFiles = () => {
    setLocalFiles([]);
  };
  const closeModal = () => {
    setIsLoading(false);
    setIsDone(false);
    clearFiles();
    toggle();
  };
  const clearFile = (index: number) => {
    const newFiles = [...localFiles];
    newFiles.splice(index, 1);
    setLocalFiles(newFiles);
  };

  const uploadFile = (files: File[]) => {
    const newFiles: MyFile[] = files.map((file) => {
      const newFile = file as MyFile;
      newFile.status = 'default';
      return newFile;
    });

    setLocalFiles([...localFiles, ...newFiles]);
  };

  const uploadAllFiles = async () => {
    if (isLoading) return;
    setIsDone(true);
    setIsLoading(true);
    for (let i = 0; i < localFiles.length; i++) {
      const file = localFiles[i];
      if (file.status === 'default' || file.status === 'error') {
        const newFiles = [...localFiles];
        newFiles[i].status = 'pending';
        setLocalFiles(newFiles);
        const response = await uploadFunction(file);
        if (response.error) {
          newFiles[i].status = 'error';
          newFiles[i].message = response.error;
          setLocalFiles(newFiles);
        } else {
          newFiles[i].status = 'success';
          newFiles[i].message = t('labels.success');
          setLocalFiles(newFiles);
        }
      }
    }
    setIsLoading(false);
  };

  return (
    <BaseModal
      open={isOpen}
      onCancel={() => {
        closeModal();
      }}
      title={title}
      width="1000px"
      smallBodyPaddingWithFooter
      withFooter={[
        sampleFileUrl ? (
          <BaseButton
            dataCy="download-button"
            key="download"
            type="secondary"
            icon={<DownloadOutlinedIcon />}
            label={t('controls.downloadSample')}
            onClick={() => {
              window.open(sampleFileUrl, '_blank');
            }}
            width={184}
            className="upload-csv-button"
          />
        ) : (
          <div />
        ),
        <div style={{ width: '100%' }} />,
        <BaseButton
          dataCy="cancel-button"
          key="cancel"
          type="secondary"
          label={t('controls.cancel')}
          onClick={() => {
            closeModal();
          }}
          className="upload-csv-button"
        />,
        <BaseButton
          dataCy="save-button"
          key="save"
          type="primary"
          label={t('controls.sendFile')}
          loading={isLoading}
          icon={<DownloadOutlinedIcon />}
          onClick={() => uploadAllFiles()}
          disabled={localFiles.length === 0 || isLoading || isDone}
          className="upload-csv-button"
        />,
      ]}
    >
      <div className="upload-csv-modal-body">
        <UploadFile
          onUpload={uploadFile}
          fileType={fileType}
          sampleFileUrl={sampleFileUrl}
          disabled={isLoading || isDone}
          multiFile
        />
      </div>
      {localFiles.map((file, index) => (
        <Tooltip title={file.message}>
          <div className="uploaded-files-list" key={`file-${index}`}>
            <div className="uploaded-file">
              <div className="uploaded-file-name">
                <DocumentIcon />
                <p>{file.name}</p>
              </div>
              {file.status === 'default' && (
                <BaseButton
                  disabled={isLoading}
                  icon={<TrashDeleteIcon />}
                  type="secondary"
                  onClick={() => clearFile(index)}
                />
              )}
              {file.status === 'success' && (
                <BaseButton type="secondary" icon={<SuccessfullyDoneIcon />} />
              )}
              {file.status === 'pending' && (
                <BaseButton type="secondary" loading />
              )}
              {file.status === 'error' && (
                <BaseButton
                  icon={<XIcon />}
                  type="danger"
                  onClick={() => clearFile(index)}
                />
              )}
            </div>
          </div>
        </Tooltip>
      ))}
    </BaseModal>
  );
};

export default MultiFileUploadModal;
