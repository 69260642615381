import useUser from 'utils/useUser';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'utils/deviceHelper';
import ActivityStatus from 'components/atoms/activityStatus';
import ReadingItem from 'components/atoms/readingItem';
import ActivityComments from 'components/molecules/comments';

import s from './styles.module.scss';
import TimeColumn from './time';
import PatientNameColumn from './patient-name';
import ProviderColumn from './provider';
import CategoryColumn from './category';

import type { IActivityData } from 'infrastructure/interfaces/i-rpm-patient-activity';
import type { TableProps } from 'antd';

export const activityReportTableColumns =
  (): TableProps<IActivityData>['columns'] => {
    const { deviceType } = useUser();

    const { t } = useTranslation();

    return [
      {
        title: t('labels.status'),
        key: 'globalStatus',
        render: (_, record) => <ActivityStatus status={record.globalStatus} />,
      },
      {
        title: t('labels.time'),
        key: 'createdAt',
        render: (_, record) => <TimeColumn data={record} />,
      },
      {
        title: t('labels.patientName'),
        key: 'patientFullName',
        render: (_, record) => <PatientNameColumn data={record} />,
      },
      {
        title: t('labels.mrn'),
        key: 'mrn',
        render: (_, record) => (
          <>
            <span>{record.patient.mrn}</span>
            {isMobile(deviceType) && !record.patient.mrn && '-'}
          </>
        ),
      },
      {
        title: t('labels.phoneNumber'),
        key: 'phoneNumber',
        render: (_, record) => <strong>{record.patient.phone}</strong>,
      },
      {
        title: t('labels.provider'),
        key: 'provider',
        render: (_, record) => <ProviderColumn data={record} />,
      },
      {
        title: t('labels.category'),
        key: 'category',
        render: (_, record) => <CategoryColumn data={record} />,
      },
      {
        title: t('labels.reading'),
        key: 'reading',
        className: s.reading,
        render: (_, record) => <ReadingItem activity={record} />,
      },
      {
        title: t('labels.commentsReviews'),
        key: 'commentsReviews',
        width: '38%',
        className: s.pad,
        render: (_, record) => <ActivityComments comments={record.comments} />,
      },
    ];
  };
