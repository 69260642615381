import React from 'react';

import type { IActivityData } from 'infrastructure/interfaces/i-rpm-patient-activity';

interface IProps {
  data: IActivityData;
}

const ProviderColumn: React.FC<IProps> = ({ data }) => {
  return (
    <div>
      {data.doctor.title} {data.doctor.firstName} {data.doctor.lastName}
      {data.doctor.professionType ? ' - ' : ' '}
      {data.doctor.professionType}
    </div>
  );
};

export default ProviderColumn;
