import React, { useCallback, useMemo, useState } from 'react';
import BaseAsyncSelectBox from 'components/atoms/base-select-box/async';
import { useDoctorSelect } from 'infrastructure/hooks/select/use-doctor-select';
import { LoginStatus } from 'infrastructure/enums';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

import type { DoctorSelect } from 'infrastructure/classes/select/doctor-select';
import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';
import type { IPaginationMeta } from 'infrastructure/interfaces';
import type { Roles } from 'infrastructure/enums';

type DoctorSelectOption = IBaseSelectBoxOption<string, DoctorSelect>;

interface IDoctorSelectBoxProps extends Omit<IBaseSelectBoxProps, 'options'> {
  showInactiveWarning?: boolean;
  clinicGuid?: string[];
  agencyGuid?: string[];
  roles?: Array<Roles>;
  status?: Array<LoginStatus>;
  onChange?: (value: string, option: DoctorSelectOption) => void;
  onLoaded?: (
    items: Array<DoctorSelectOption>,
    selected?: DoctorSelectOption,
  ) => void;
}

const DoctorSelectBox: React.FC<IDoctorSelectBoxProps> = (props) => {
  const {
    clinicGuid,
    agencyGuid,
    roles,
    status,
    onLoaded,
    onChange,
    showInactiveWarning = true,
  } = props;

  const { t } = useTranslation();

  const { meta, loadDoctors } = useDoctorSelect();

  const [doctorStatus, setDoctorStatus] = useState<LoginStatus>(
    LoginStatus.Active,
  );
  const error = useMemo(() => {
    if (doctorStatus === LoginStatus.Inactive) {
      return t('loginStatuses.inactive');
    }
    if (doctorStatus === LoginStatus.Blocked) {
      return t('loginStatuses.blocked');
    }
  }, [doctorStatus]);

  const fetchOptions = useCallback(
    async (search: string, metaProps: IPaginationMeta) => {
      const doctors = await loadDoctors({
        ...metaProps,
        search,
        clinicGuid,
        agencyGuid,
        roles,
        status,
      });

      if (doctors)
        return doctors.map((el) => {
          return {
            label: el.label,
            value: el.guid,
            record: el,
          };
        });

      return [];
    },
    [clinicGuid, agencyGuid, roles, status],
  );

  const onValueLoaded = (
    items: Array<DoctorSelectOption>,
    selected?: DoctorSelectOption,
  ) => {
    if (selected?.record?.status) {
      setDoctorStatus(selected?.record?.status);
    }
    if (onLoaded) onLoaded(items, selected);
  };

  const onValueChange = (value: string, option: DoctorSelectOption) => {
    if (option?.record?.status) {
      setDoctorStatus(option?.record?.status);
    }
    if (onChange) onChange(value, option);
  };

  return (
    <div className={s.wrapper}>
      <BaseAsyncSelectBox
        key={`${clinicGuid}-${agencyGuid}`}
        fetchOptions={fetchOptions}
        meta={meta}
        canSearch
        {...props}
        onValueLoaded={onValueLoaded}
        onChange={onValueChange}
      />
      {error && showInactiveWarning && <div className={s.error}>*{error}</div>}
    </div>
  );
};

export default DoctorSelectBox;
