import i18next from 'i18next';
import { HTML_ARROW } from 'infrastructure/consts/symbols';

import s from './styles.module.scss';

import type { ClinicLog } from 'infrastructure/classes/logs/clinic-log';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

const FIELDS_WITH_HTML_VALUE = [
  'notes',
  'critical After Hours',
  'critical Business Hours',
];

export const clinicLogColumns = (): IBaseTableColumns<ClinicLog> => {
  const renderValue = (value: string, field: string) => {
    if (value === 'null') return value;

    if (FIELDS_WITH_HTML_VALUE.includes(field)) {
      return (
        <span
          className="ql-editor ql-index-1"
          dangerouslySetInnerHTML={{ __html: value }}
        />
      );
    }

    if (field === 'service Delivery Thresholds') {
      return Number(value) > 15
        ? `${Number(value) / 60} Hours`
        : `${value} minutes`;
    }

    return value;
  };

  return [
    {
      label: i18next.t('labels.dateTime'),
      key: 'date',
      width: '100px',
      render(date) {
        return <div className={s.date}>{date}</div>;
      },
    },
    {
      label: i18next.t('labels.operationType'),
      key: 'logType',
      width: '130px',
      responsive: ['md'],
      render(_) {
        return <div className={s.text}>UPDATE</div>;
      },
    },
    {
      label: i18next.t('labels.changes'),
      key: 'changes',
      render(_, record) {
        return record.changes.map((el, index) => (
          <div key={index} className={s.change}>
            <span className={s.date}>{el.field}</span>
            <div className={s.info}>
              <span className={s.from}>
                {`${i18next.t('labels.from')}: `}
                {renderValue(el.from, el.field)}
              </span>
              {HTML_ARROW}
              <span className={s.to}>
                {` ${i18next.t('labels.to')}: `}
                {/* {renderValue(el.from, el.field)} */}
                {renderValue(el.to, el.field)}
              </span>
            </div>
          </div>
        ));
      },
    },
    {
      label: i18next.t('labels.user'),
      key: 'person',
      width: '220px',
      render(_, record) {
        return (
          <div className={s.text}>
            {record.person} - {record.userType}
          </div>
        );
      },
    },
  ];
};
