import React, { useState } from 'react';
import ConversationHeader from 'components/atoms/chat/conversationHeader';
import ConversationsList from 'components/molecules/smsDashboard/conversationsList';
import ConversationTabs from 'components/atoms/chat/conversationTabs';

const Conversations = () => {
  const [selectMode, setSelectMode] = useState<boolean>(false);
  const [checkedConversations, setCheckedConversations] = useState([]);

  return (
    <div className="conversations">
      <ConversationTabs
        setSelectMode={setSelectMode}
        setCheckedConversations={setCheckedConversations}
      />
      <ConversationHeader
        setSelectMode={setSelectMode}
        selectMode={selectMode}
        checkedConversations={checkedConversations}
        setCheckedConversations={setCheckedConversations}
      />
      <ConversationsList
        selectMode={selectMode}
        checkedConversations={checkedConversations}
        setCheckedConversations={setCheckedConversations}
      />
    </div>
  );
};

export default Conversations;
