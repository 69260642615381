import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandableFilterPanel, {
  ExpandableFilterPanelItem,
} from 'components/molecules/expandable-filter-panel';
import useKey from 'infrastructure/hooks/utils/use-key';
import BaseRangeDatepicker from 'components/atoms/base-range-datepicker';
import PatientSelectBox from 'components/molecules/patient-select-box';
import ReadingStatusSelectBox from 'components/molecules/reading-status-select-box';
import BooleanSelectBox from 'components/molecules/boolean-select-box';
import DoctorSelectBox from 'components/molecules/doctor-select-box';
import ClinicSelectBox from 'components/molecules/clinic-select-box';
import { Tooltip } from 'antd';
import InsuranceProviderSelectBox from 'components/molecules/insurance-provider-select-box';
import { rpmDashboardFilterDoctorSearchRoles } from 'infrastructure/enums';

import s from './styles.module.scss';

import type { RangePickerValue } from 'components/atoms/base-range-datepicker';
import type { IActivityReportListFilters } from 'infrastructure/interfaces';

interface IProps {
  data: IActivityReportListFilters;
  updateFilter: (data: IActivityReportListFilters) => void;
  resetFilter: () => void;
  count: number;
}

const ExpandableActivityReportsFilters: React.FC<IProps> = (props) => {
  const { data, count, updateFilter, resetFilter } = props;

  const { t } = useTranslation();
  const { key, changeKey } = useKey();

  const handleReset = () => {
    if (resetFilter) resetFilter();
    setTimeout(changeKey, 200);
  };

  const preparedDate = useMemo(() => {
    if (!data.startTime) return undefined;
    if (!data.endTime) return undefined;

    return [
      new Date(data.startTime),
      new Date(data.endTime),
    ] as RangePickerValue;
  }, [data.startTime, data.endTime]);

  return (
    <ExpandableFilterPanel
      filtersCount={count}
      onReset={handleReset}
      openWithValue
      isExpanded
    >
      <div className={s.form} key={key}>
        <ExpandableFilterPanelItem borderBottom label={t('labels.dateRange')}>
          <BaseRangeDatepicker
            value={preparedDate}
            onChange={(dates) => {
              const [startDate, endDate] = dates as [Date, Date];
              updateFilter({
                startTime: startDate.getTime(),
                endTime: endDate.getTime(),
                page: undefined,
              });
            }}
            disabledDate={(current) => {
              return current && current > new Date();
            }}
            withPresets
            isMaxDayToday
          />
        </ExpandableFilterPanelItem>
        <ExpandableFilterPanelItem
          borderBottom
          label={t('labels.patientFilter')}
        >
          <PatientSelectBox
            placeholder={t('labels.searchPatient')}
            value={data.patientGuid}
            onChange={(patientGuid) => {
              updateFilter({ patientGuid, page: undefined });
            }}
          />
        </ExpandableFilterPanelItem>
        <ExpandableFilterPanelItem borderBottom label={t('labels.showStatus')}>
          <ReadingStatusSelectBox
            withAll
            allowClear
            value={data.status}
            onChange={(status) => {
              updateFilter({ status, page: undefined });
            }}
          />
        </ExpandableFilterPanelItem>
        <ExpandableFilterPanelItem borderBottom label={t('labels.sort')}>
          <BooleanSelectBox
            allowClear
            trueLabel={t('labels.oldest')}
            falseLabel={t('labels.newest')}
            value={data.reverse}
            onChange={(reverse) => {
              updateFilter({ reverse, page: undefined });
            }}
          />
        </ExpandableFilterPanelItem>
        <ExpandableFilterPanelItem label={t('labels.provider')}>
          <DoctorSelectBox
            allowClear
            placeholder={t('labels.search')}
            roles={rpmDashboardFilterDoctorSearchRoles}
            mode="multiple"
            value={data.doctorGuid}
            onChange={(doctorGuid: any) => {
              updateFilter({ doctorGuid, page: undefined });
            }}
          />
        </ExpandableFilterPanelItem>
        <ExpandableFilterPanelItem label={t('labels.clinic')}>
          <ClinicSelectBox
            allowClear
            placeholder={t('labels.search')}
            value={data.clinicGuid}
            onChange={(clinicGuid) => {
              updateFilter({ clinicGuid, page: undefined });
            }}
          />
        </ExpandableFilterPanelItem>
        <ExpandableFilterPanelItem label={t('labels.insuranceProvider')}>
          <Tooltip
            placement="top"
            trigger="hover"
            title={
              !data.clinicGuid && t('messages.disableInsuranceProviderFilter')
            }
          >
            <div>
              <InsuranceProviderSelectBox
                allowClear
                withAll
                disabled={!data.clinicGuid}
                value={data.insuranceType}
                onChange={(insuranceType) => {
                  updateFilter({ insuranceType, page: undefined });
                }}
              />
            </div>
          </Tooltip>
        </ExpandableFilterPanelItem>
        <ExpandableFilterPanelItem label={t('labels.childClinics')}>
          <Tooltip
            placement="top"
            trigger="hover"
            title={!data.clinicGuid && t('messages.disableChildClinicFilter')}
          >
            <div>
              <BooleanSelectBox
                allowClear
                trueLabel={t('labels.included')}
                falseLabel={t('labels.excluded')}
                disabled={!data.clinicGuid}
                value={data.childClinic}
                onChange={(childClinic) => {
                  updateFilter({ childClinic, page: undefined });
                }}
              />
            </div>
          </Tooltip>
        </ExpandableFilterPanelItem>
      </div>
    </ExpandableFilterPanel>
  );
};

export default ExpandableActivityReportsFilters;
