import { useQuery } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';

import useApi from '../../use-api';

import type {
  IActivityReportListFilters,
  IPartialPaginationMeta,
} from 'infrastructure/interfaces';
import type { IRpmPatientActivity } from 'infrastructure/interfaces/i-rpm-patient-activity';

export type TActivityReportFilter = IActivityReportListFilters &
  IPartialPaginationMeta;

const apiUrl = `core/patients/readings`;

const prepareFilters = (filters?: TActivityReportFilter) => {
  if (!filters) return {};
  const copiedFilters = structuredClone(filters);

  if (copiedFilters.doctorGuid?.length) {
    copiedFilters.doctorGuid = copiedFilters.doctorGuid?.join(',') as any;
  }

  if (!copiedFilters.clinicGuid) {
    delete copiedFilters.childClinic;
  }

  return copiedFilters;
};

type UseGetActivityReport = {
  loading: boolean;
  reports: IRpmPatientActivity['data'];
  meta: TActivityReportFilter;
};

interface IUseGetActivityReportProps {
  meta?: IActivityReportListFilters & IPartialPaginationMeta;
}

export const useGetActivityReport = (
  props: IUseGetActivityReportProps,
): UseGetActivityReport => {
  const { items = 100, page = 1 } = props?.meta ?? {};
  const { loadData } = useApi();

  const preparedFilter = prepareFilters(props?.meta);
  const queryKey = [apiUrl, preparedFilter];

  const { data, isLoading } = useQuery({
    queryKey,
    staleTime: STALE_TIME,
    queryFn: () =>
      loadData<IRpmPatientActivity>(apiUrl, {
        sortField: JSON.stringify(props?.meta?.sortField),
        items,
        page,
        ...preparedFilter,
      }).then((res) => {
        if (res) {
          return {
            count: res.count,
            items: res.data,
          };
        }
      }),
    enabled: !!preparedFilter.startTime && !!preparedFilter.endTime,
  });

  const meta = {
    totalCount: data?.count ?? 0,
    page,
    items,
    ...props?.meta,
  };

  return {
    loading: isLoading,
    reports: data ? data.items : [],
    meta,
  };
};
