import React from 'react';
import { Row } from 'reactstrap';

import Metric from './metric';

import type { FC } from 'react';
import type { PatientAverage } from 'utils/hooks/turkDashboard/useAverages';

type Props = {
  data: PatientAverage[];
};

const Average: FC<Props> = ({ data }) => {
  if (!data) return null;
  return (
    <Row className="average-metrics-main flex-nowrap">
      {data.map((metric) => (
        <Metric key={metric.name} name={metric.name} value={metric.value} />
      ))}
    </Row>
  );
};

export default Average;
