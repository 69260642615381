import React, { forwardRef, useImperativeHandle } from 'react';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import BaseGrid from 'components/atoms/base-grid';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import BaseInput from 'components/atoms/base-input';
import ReadingTypeSelectBox from 'components/molecules/reading-type-select-box';
import BaseSwitch from 'components/atoms/base-switch';
import BaseTimePicker from 'components/atoms/base-timepicker';
import {
  DeviceGeneration,
  DeviceAlertStatus,
  DateFormats,
} from 'infrastructure/enums';
import { usePatientDevices } from 'infrastructure/hooks/device/use-patient-devices';
import { PatientDevice } from 'infrastructure/classes/patient/patient-device';
import { deepEqual } from 'infrastructure/functions';

import { deviceValidationSchema } from './validation-schema';
import s from './styles.module.scss';

import type { ReadingType } from 'infrastructure/enums';
import type {
  IUpdatePatientDevice,
  IUpdatePatientDeviceAlert,
} from 'infrastructure/interfaces';

export interface IDeviceUpdateFormRef {
  submit: () => Promise<void>;
  reset: () => void;
}

interface IDeviceUpdateFormProps {
  patientGuid: string;
  data: PatientDevice;
  onCancel?: () => void;
  onSaved?: () => void;
}

const DeviceUpdateForm = forwardRef<
  IDeviceUpdateFormRef,
  IDeviceUpdateFormProps
>((props, ref) => {
  const { patientGuid, data, onCancel, onSaved } = props;

  const isGen2 = data.generation === DeviceGeneration.Gen2;

  const { t } = useTranslation();

  const { loading, updatePatientDevices, updatePatientDevicesAlert } =
    usePatientDevices({ patientGuid, initialLoading: false });

  const formik = useFormik<
    IUpdatePatientDevice & {
      alarm?: IUpdatePatientDeviceAlert;
    }
  >({
    initialValues: {
      patientGuid,
      deviceId: data.deviceId,
      manufacturer: data.manufacturer,
      readingTypes: data.readingTypes,
      alarm: data.alarm
        ? {
            enable: data.alarm.status !== DeviceAlertStatus.Disabled,
            start: data.alarm.start,
            stop: data.alarm.end,
          }
        : undefined,
    },
    onSubmit: async (values) => {
      await updatePatientDevices({
        ...values,
        readingTypes: PatientDevice.prepareReadingTypes(values.readingTypes),
      });
      if (isGen2 && values.alarm) {
        if (data.alarm) {
          const isAlarmEqual = deepEqual(values.alarm, {
            enable: data.alarm.status !== DeviceAlertStatus.Disabled,
            start: data.alarm.start,
            stop: data.alarm.end,
          });
          if (!isAlarmEqual) {
            await updatePatientDevicesAlert(values.deviceId, values.alarm);
          }
        }
      }
      if (onSaved) onSaved();
    },
    validateOnChange: false,
    validationSchema: deviceValidationSchema(),
  });

  const { submitForm, resetForm } = formik;

  const setReadingType = (readingType: ReadingType) => {
    formik.setFieldValue('readingTypes', [readingType]);
  };

  useImperativeHandle(ref, () => ({
    submit: submitForm,
    reset: resetForm,
  }));

  return (
    <BaseSpinWrapper spinning={loading}>
      <BaseForm
        loading={loading}
        formik={formik}
        plaintext={false}
        readonly={false}
        onSave={submitForm}
        onCancel={onCancel}
        withControls
      >
        <BaseFormItem name="manufacturer" label={t('labels.deviceName')}>
          <BaseInput />
        </BaseFormItem>
        <BaseFormItem name="deviceId" label={t('labels.deviceId')}>
          <BaseInput readonly plaintext />
        </BaseFormItem>
        <BaseFormItem name="readingTypes" label={t('labels.readingType')}>
          <ReadingTypeSelectBox
            onChange={(value) => {
              setReadingType(value);
            }}
          />
        </BaseFormItem>
        {isGen2 ? (
          <div>
            <div className={s.alert}>
              <BaseFormItem
                labelDirection="left"
                name="alarm.enable"
                label={t('labels.adherenceAlertState')}
              >
                <BaseSwitch />
              </BaseFormItem>
            </div>
            <BaseGrid columns={2}>
              <BaseFormItem
                name="alarm.start"
                label={t('labels.adherenceStartTime')}
              >
                <BaseTimePicker format={DateFormats['HH:mm']} />
              </BaseFormItem>
              <BaseFormItem
                name="alarm.stop"
                label={t('labels.adherenceEndTime')}
              >
                <BaseTimePicker format={DateFormats['HH:mm']} />
              </BaseFormItem>
            </BaseGrid>
          </div>
        ) : (
          <></>
        )}
      </BaseForm>
    </BaseSpinWrapper>
  );
});

DeviceUpdateForm.displayName = 'DeviceUpdateForm';

export default DeviceUpdateForm;
