import React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from 'infrastructure/consts/routes';

import type { IActivityData } from 'infrastructure/interfaces/i-rpm-patient-activity';

interface IProps {
  data: IActivityData;
}

const PatientNameColumn: React.FC<IProps> = ({ data }) => {
  return (
    <div>
      <Link to={Routes.patientDetail(data.patient.guid)}>
        <strong>
          {data.patient.firstName} {data.patient.lastName}
        </strong>
      </Link>
    </div>
  );
};

export default PatientNameColumn;
