import React from 'react';
import classNames from 'classnames';
import formatDate from 'utils/hooks/shared/formatDate';
import common from 'constants/common';
import ConversationClosedIcon from 'assets/img/conversation-closed.svg';
import ConversationOpenedIcon from 'assets/img/conversation-opened.svg';
import {
  PlatformCodeEnums,
  PlatformEnums,
} from 'infrastructure/enums/platformEnums';
import {
  ConversationActionEnums,
  MessageStatusEnums,
  MessageStatusReadEnums,
} from 'infrastructure/enums/messageStatusEnums';
import { Image } from 'antd';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import type { ConversationType } from 'infrastructure/interfaces';

interface IMessageProps {
  left: boolean;
  createdAt: Date;
  body: string;
  action: string;
  authorType: string;
  authorName?: string;
  status: string;
  to: string;
  from: string;
  conversationType: ConversationType;
  mediaUrl: string | null;
  readBy: string | null;
  readAt: Date | null;
}

const formatMessageStatus = (status: string) => {
  if (status === MessageStatusEnums.delivered) {
    return MessageStatusReadEnums.read;
  }
  if (status === MessageStatusEnums.sent) {
    return MessageStatusReadEnums.unread;
  }
  return status;
};

const Message: React.FC<IMessageProps> = (props) => {
  const {
    left,
    createdAt,
    body,
    authorType,
    status,
    to,
    from,
    conversationType,
    authorName,
    action,
    mediaUrl,
    readBy,
    readAt,
  } = props;

  const formattedDate = formatDate(
    createdAt,
    common.dateFormats.hoursMinutesPM,
  );
  const formattedFullDate = formatDate(
    createdAt,
    common.dateFormats.fullDateUSTimeAMPM,
  );
  const messageStatus = formatMessageStatus(status);

  const getMessageType = (id: string) => {
    if (id === PlatformCodeEnums.RPM) {
      return PlatformEnums.RPM;
    }
    if (id === PlatformCodeEnums.CCM) {
      return PlatformEnums.CCM;
    }
  };

  const messageType = left ? getMessageType(to) : getMessageType(from);

  const messageClassNames = classNames('message-item', left ? 'left' : 'right');
  const actionIcon =
    action === ConversationActionEnums.closed
      ? ConversationClosedIcon
      : ConversationOpenedIcon;

  const actionTypeSms = (
    <div className={messageClassNames}>
      <div className="message-text action">
        {conversationType === PlatformEnums.UNREGISTERED && (
          <span className="message-type"> {messageType}</span>
        )}
        <div className="message-inner-body">
          <div>
            <img src={actionIcon} alt="conversation-closed" />
          </div>
          <p className="body">{body}</p>
          <p className="gray">{formattedDate}</p>
        </div>
        <p className="gray">{authorName}</p>
      </div>
      <div
        className="message-footer"
        title={`${messageStatus} | ${formattedFullDate}`}
      >
        {authorType !== 'patient' ? (
          <div className={`message-status-badge ${messageStatus}`} />
        ) : null}
      </div>
    </div>
  );

  return action === ConversationActionEnums.sms ? (
    <div className={messageClassNames}>
      <div className="message-text">
        <div className="message-time">
          <p className="author">{authorName}</p>
          <div className="time-and-type-block">
            <p className="gray">{formattedDate}</p>
            {conversationType === PlatformEnums.UNREGISTERED && (
              <span className="message-type"> {messageType}</span>
            )}
          </div>
        </div>
        <div>{mediaUrl && <Image width={40} src={mediaUrl} />}</div>
        <p className="message-body">{body}</p>
        <div
          className="message-footer"
          title={`${messageStatus} | ${formattedFullDate}`}
        >
          {authorType === 'patient' ? (
            <>
              {readBy} -{' '}
              {DateFormatter({
                date: readAt ?? '',
                format: DateFormats['MM-dd-yyyy | h:mm a'],
              })}
              <div className={`message-status-badge ${messageStatus}`} />
            </>
          ) : (
            <div className={`message-status-badge ${messageStatus}`} />
          )}
        </div>
      </div>
    </div>
  ) : (
    actionTypeSms
  );
};

export default Message;
