import i18next from 'i18next';
import { ModifiedArray } from 'infrastructure/classes/common/modified-array';
import { ReadingStatus } from 'infrastructure/enums';

export interface IReadingStatusItem {
  id: ReadingStatus | null;
  name: string;
  color?: string;
}

type ReadingStatusArray = ModifiedArray<IReadingStatusItem>;

export const readingStatusesDataSource = (
  withAll = false,
): ReadingStatusArray => {
  const dataSource = new ModifiedArray<IReadingStatusItem>(
    {
      id: ReadingStatus.Normal,
      name: i18next.t('labels.normal'),
    },
    {
      id: ReadingStatus.Risk,
      name: i18next.t('labels.atRisk'),
    },
    {
      id: ReadingStatus.Critical,
      name: i18next.t('labels.critical'),
    },
  );

  if (withAll) {
    dataSource.unshift({
      id: null,
      name: i18next.t('labels.all'),
    });
  }

  return dataSource;
};
