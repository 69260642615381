import React from 'react';
import classNames from 'classnames';

import s from './style.module.scss';

interface IBaseCheckboxProps {
  label?: string;
  disabled?: boolean;
  loading?: boolean;
  labelLeft?: boolean;
  labelRight?: boolean;
  rtl?: boolean;
  checked: boolean;
  onChange: (isChecked: boolean) => void;
}

const BaseCheckbox: React.FC<IBaseCheckboxProps> = (props) => {
  const {
    label,
    checked,
    onChange,
    disabled = false,
    loading,
    rtl = false,
    labelLeft,
    labelRight,
  } = props;

  const bodyClassNames = classNames(s.body, {
    [s.rtl]: rtl,
  });

  const labelClassNames = classNames(s.label, {
    [s['label-left']]: labelLeft,
    [s['label-right']]: labelRight,
  });

  return (
    <label className={s['base-checkbox']}>
      <div className={bodyClassNames}>
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
          disabled={disabled || loading}
        />
        {label && <span className={labelClassNames}>{label}</span>}
      </div>
    </label>
  );
};

export default BaseCheckbox;
