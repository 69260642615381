import withLoader from 'components/organisms/withLoader';
import { useTranslation } from 'react-i18next';
import { useActivitySendReport } from 'infrastructure/hooks/rpm/activityReport/use-send-report';
import { useActivityQueryFilter } from 'infrastructure/hooks/rpm/activity/use-query-filter';
import NavigationHeader from 'components/molecules/navigation-header';
import ExpandableActivityReportsFilters from 'components/molecules/activity-report/expandable-filters';
import ActivityReportTable from 'components/molecules/activity-report/table';
import BaseDownloadButton from 'components/atoms/download-button';
import BaseContainer from 'components/atoms/base-container';

import s from './styles.module.scss';

const Activity = () => {
  const { t } = useTranslation();
  const { filter, count, resetFilter, updateFilter } = useActivityQueryFilter();
  const { isLoading, sendReportToEmail } = useActivitySendReport(filter);

  return (
    <BaseContainer>
      <NavigationHeader title={t('labels.reportActivity')}>
        {withLoader(
          isLoading,
          <BaseDownloadButton
            onClick={sendReportToEmail}
            disabled={isLoading}
          />,
        )}
      </NavigationHeader>
      <div className={s.page}>
        <ExpandableActivityReportsFilters
          count={count}
          data={filter}
          updateFilter={updateFilter}
          resetFilter={resetFilter}
        />
        <ActivityReportTable filters={filter} updateFilter={updateFilter} />
      </div>
    </BaseContainer>
  );
};

export default Activity;
