import { useTranslation } from 'react-i18next';
import RiskScoreTag from 'components/atoms/tags/riskScoreTag';
import DoNotCallTag from 'components/atoms/tags/DoNotCallTag';
import EmailUnsubscribedTag from 'components/atoms/tags/EmailUnsubscribedTag';
import SlaTag from 'components/atoms/tags/SlaTag';
import classNames from 'classnames';
import PcmTag from 'components/atoms/tags/PcmTag';
import { isCcmModule } from 'infrastructure/functions';
import { isMobile } from 'utils/deviceHelper';
import { useAppDispatch, useAppSelector } from 'store';
import ListIcon from 'components/atoms/icons/listIcon';
import { type FC } from 'react';
import {
  ClinicStatus,
  LoginStatus,
  type ModuleType,
} from 'infrastructure/enums';

import s from './styles.module.scss';
import MobileMiniNav from '../mini-nav/mobile-mini-nav';

interface PatientMiniTopBannerProps {
  fullName: string;
  riskScore?: number;
  clinicName: string;
  clinicStatus: string;
  doNotCall?: boolean;
  showEmailTag: boolean;
  physician?: string;
  physicianStatus?: string;
  sla?: number;
  fullWight?: boolean;
  pcmStatus?: boolean;
  statusColor?: string;
  module: ModuleType;
}

const PatientMiniTopBanner: FC<PatientMiniTopBannerProps> = ({
  fullName,
  riskScore,
  clinicName,
  clinicStatus,
  doNotCall,
  showEmailTag,
  physician,
  physicianStatus,
  sla,
  fullWight = false,
  pcmStatus,
  statusColor,
  module,
}) => {
  const { t } = useTranslation();
  const deviceType = useAppSelector((state) => state.user.deviceType);
  const {
    modalWindows: { toggleOpenMobileMiniNav },
  } = useAppDispatch();

  return (
    <>
      <div
        data-cy="patient-header-small"
        className={classNames(s.wrapper, { [s['full-wight']]: fullWight })}
      >
        <div
          className={s['status-indicator']}
          style={{ backgroundColor: statusColor }}
        />
        <div className={s['title-wrapper']}>
          <p data-cy="patient-name" className={s.title}>
            {fullName}
          </p>
          {!isMobile(deviceType) && (
            <>
              {typeof sla !== 'undefined' && <SlaTag hours={sla} small />}
              {doNotCall && <DoNotCallTag small />}
              {showEmailTag && <EmailUnsubscribedTag small />}
              {pcmStatus && <PcmTag small />}
            </>
          )}
        </div>
        <div className={s['info-wrapper']}>
          <p data-cy="physician-name" className={s['physician-title']}>
            {physician}
            {(physicianStatus === LoginStatus.Inactive ||
              physicianStatus === LoginStatus.Blocked) && (
              <>
                {' '}
                <span className={classNames(s.status)}>
                  ({t(`loginStatuses.${physicianStatus}`)})
                </span>
              </>
            )}
          </p>
          <p data-cy="clinic-name" className={s['clinic-title']}>
            {clinicName}
            {(clinicStatus === ClinicStatus.Delinquent ||
              clinicStatus === ClinicStatus.Inactive) && (
              <>
                {' '}
                <span className={classNames(s.status)}>
                  ({t(`clinicStatuses.${clinicStatus}`)})
                </span>
              </>
            )}
          </p>
          {typeof riskScore !== 'undefined' && isCcmModule(module) && (
            <RiskScoreTag score={riskScore} />
          )}
        </div>
        <div
          className={s['mobile-mini-vav']}
          onClick={() => toggleOpenMobileMiniNav()}
        >
          <ListIcon />
        </div>
      </div>
      <MobileMiniNav module={module} />
    </>
  );
};

export default PatientMiniTopBanner;
