import React from 'react';

const SuccessfullyDoneIcon = () => (
  <svg
    width="74"
    height="74"
    viewBox="0 0 74 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.5073 48.5456C33.5694 49.4819 32.3031 50.0047 30.9851 50C29.6672 49.9952 28.4046 49.4633 27.4732 48.5204L22.0106 42.9966C20.6387 41.6093 20.6636 39.3645 22.0854 38.0277C23.4323 36.7161 25.5775 36.7665 26.8746 38.0781L30.9903 42.2399L47.0291 26.0215C48.376 24.6595 50.5461 24.6595 51.8931 26.0215L51.9929 26.1224C53.3398 27.4845 53.3398 29.7041 51.9679 31.0661L34.5073 48.5456Z"
      fill="currentColor"
    />
    <circle cx="37" cy="37" r="35" stroke="currentColor" strokeWidth="4" />
  </svg>
);

export default SuccessfullyDoneIcon;
