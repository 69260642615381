import React from 'react';
import { camelCaseToWords } from 'utils/formatReportMessage';
import { ReadingType } from 'infrastructure/enums';

import type { IActivityData } from 'infrastructure/interfaces/i-rpm-patient-activity';

interface IProps {
  data: IActivityData;
}

const CategoryColumn: React.FC<IProps> = ({ data }) => {
  return (
    <div>
      <span className={`reading ${data.globalStatus}`}>
        {data.readingType !== ReadingType.SpO2
          ? camelCaseToWords(data.readingType)
          : data.readingType}
      </span>
    </div>
  );
};

export default CategoryColumn;
