import i18next from 'i18next';
import { InsuranceProvider } from 'infrastructure/enums';
import { ModifiedArray } from 'infrastructure/classes/common/modified-array';

export interface IInsuranceProviderItem {
  id: InsuranceProvider | null;
  name: string;
}

type InsuranceProviderArray = ModifiedArray<IInsuranceProviderItem>;

export const insuranceProvidersDataSource = (
  withAll = false,
): InsuranceProviderArray => {
  const dataSource = new ModifiedArray<IInsuranceProviderItem>(
    {
      id: InsuranceProvider.Medicare,
      name: i18next.t('insuranceProviders.medicare'),
    },
    {
      id: InsuranceProvider.TexasMedicaid,
      name: i18next.t('insuranceProviders.texasMedicaid'),
    },
    {
      id: InsuranceProvider.PrivatePay,
      name: i18next.t('insuranceProviders.privatePay'),
    },
    {
      id: InsuranceProvider.TexasMedicaidHMO,
      name: i18next.t('insuranceProviders.texasMedicaidHMO'),
    },
    {
      id: InsuranceProvider.Commercial,
      name: i18next.t('insuranceProviders.commercial'),
    },
    {
      id: InsuranceProvider.CaliforniaMedicaid,
      name: i18next.t('insuranceProviders.californiaMedicaid'),
    },
  );

  if (withAll) {
    dataSource.unshift({
      id: null,
      name: i18next.t('labels.all'),
    });
  }

  return dataSource;
};
