import { makeid } from 'utils/helpers/makeid';

import { ChangeLog } from './change-log';

import type { DateString } from 'infrastructure/types';
import type { IChangeLog, IClinicLog } from 'infrastructure/interfaces';

export class ClinicLog {
  key: string;
  guid: string;
  clinicGuid: string;
  changes: IChangeLog[];
  date: DateString;
  person: string;
  userType: string;
  logType: string;

  constructor(data: IClinicLog) {
    this.key = makeid(5);
    this.guid = data.guid;
    this.clinicGuid = data.clinicGuid;
    this.changes = data.changes.map((el) => new ChangeLog(el));
    this.date = data.createdAt;
    this.person = data.author.fullName;
    this.userType = data.author.userType;
    this.logType = data.type;
  }
}
