import { useTranslation } from 'react-i18next';
import { parseDateSafe } from 'utils/timeHelper';
import { format } from 'date-fns';
import common from 'constants/common';

import BillingClaimsResultModal from './resultModal';

import type { TableProps } from 'antd';
import type { EpicProcess } from 'infrastructure/hooks/billingTools/epic/types';

export const useColumns = (): TableProps<EpicProcess>['columns'] => {
  const { t } = useTranslation();

  const formatCreatedAt = (createdAt: string) => {
    const createdAtDate = parseDateSafe(createdAt);
    if (!createdAtDate) return null;
    return format(createdAtDate, common.dateFormats.dateAndTime);
  };

  return [
    {
      title: t('labels.createdAt'),
      width: '20%',
      render: (_, record) => (
        <>
          <div>{formatCreatedAt(record.createdAt)}</div>
        </>
      ),
    },
    {
      title: t('labels.filename'),
      width: '60%',
      render: (_, record) => <div>{record.name}</div>,
    },
    {
      title: t('labels.result'),
      width: '20%',
      render: (_, record) => (
        <BillingClaimsResultModal
          result={record.result}
          filename={record.name}
        />
      ),
    },
  ];
};
