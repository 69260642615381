import React, { useState } from 'react';
import classNames from 'classnames';

import BaseWrapper from '../base-wrapper';
import s from './styles.module.scss';

import type { IBaseComponentProps } from 'infrastructure/interfaces';

export interface IBaseTab<TKey = any> extends IBaseComponentProps {
  key: string | number | TKey;
  label: string;
  disabled?: boolean;
}

interface IBaseTabsProps extends IBaseComponentProps {
  tabs: Array<IBaseTab>;
  defaultIndex?: number;
  size?: 'small' | 'default';
  type?: 'rounded' | 'default';
  disabled?: boolean;
  contentClassName?: string;
  onChange?: (tab: IBaseTab, index: number) => void;
  maxWidth?: string;
  wrapperClassName?: string;
}

const BaseTabs: React.FC<IBaseTabsProps> = (props: IBaseTabsProps) => {
  const {
    tabs,
    onChange,
    defaultIndex = 0,
    disabled = false,
    size = 'default',
    type = 'default',
    style,
    className,
    contentClassName,
    wrapperClassName,
    maxWidth,
  } = props;

  const [selectedTab, setSelectedTab] = useState<IBaseTab>(tabs[defaultIndex]);

  const tabsClassNames = classNames(
    s['tabs-wrapper'],
    s[size],
    className,
    {
      [s.disabled]: disabled,
    },
    s[type],
  );

  const tabClassNames = (tab: IBaseTab) =>
    classNames(
      tab.className,
      s['tab-item'],
      {
        [s.active]: tab.key === selectedTab.key,
        [s.disabled]: tab.disabled,
      },
      s[type],
    );

  const contentClassNames = classNames(s.content, contentClassName, {
    [s.disabled]: disabled,
  });

  const onTabClick = (tab: IBaseTab, index: number) => {
    setSelectedTab(tab);
    if (onChange) onChange(tab, index);
  };

  return (
    <div className={wrapperClassName}>
      <div style={{ width: maxWidth }} className={s['tabs-scroll-container']}>
        <ul style={style} className={tabsClassNames}>
          {tabs.map((tab, index) => (
            <li
              data-cy={`tab-${tab.key}`}
              style={tab.style}
              key={`${tab.key}-${index}`}
              className={tabClassNames(tab)}
              onClick={() => onTabClick(tab, index)}
            >
              {tab.label}
            </li>
          ))}
        </ul>
      </div>
      {selectedTab.children && (
        <BaseWrapper className={contentClassNames}>
          {selectedTab.children}
        </BaseWrapper>
      )}
    </div>
  );
};

export default BaseTabs;
