import React, { useState } from 'react';
import DragAndDropIcon from 'components/atoms/icons/dragAndDropIcon';
import LockIcon from 'components/atoms/icons/lockIcon';

import type { ChangeEventHandler, DragEventHandler, FC } from 'react';

interface Props {
  onUpload: (file: File[]) => void;
  fileType: 'csv' | 'json';
  sampleFileUrl?: string;
  disabled: boolean;
  multiFile?: boolean;
}

const UploadFile: FC<Props> = ({
  onUpload,
  fileType,
  sampleFileUrl,
  disabled,
  multiFile = false,
}) => {
  const [dropping, setDropping] = useState(false);
  const [uploadError, setUploadError] = useState<null | string>(null);

  const validateFile = (file: File): string | undefined => {
    if (!file.type.includes(fileType)) {
      return `File type of [${file.type}] is not allowed!`;
    }

    if (file.size > 10485760) {
      const size = file.size / (1024 * 1024);
      return `File size of ${size.toFixed(2)} MB is larger than 10MB!`;
    }
    return undefined;
  };

  const handleFile = async (files: FileList) => {
    if (disabled) return;
    setDropping(false);
    const filesArray = Array.from(files);
    let isValid = true;
    filesArray.forEach((file) => {
      const error = validateFile(file);
      if (error) {
        setUploadError(error);
        isValid = false;
      }
    });
    if (isValid) {
      onUpload(filesArray);
    }
  };

  const handleDrag: DragEventHandler<HTMLElement> = (e) => {
    if (disabled) return;
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDropping(true);
    } else if (e.type === 'dragleave') {
      setDropping(false);
    }
  };

  const handleDrop: DragEventHandler<HTMLElement> = (e) => {
    if (disabled) return;
    e.preventDefault();
    e.stopPropagation();
    handleFile(e.dataTransfer.files);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (disabled) return;
    e.preventDefault();
    if (e.target.files) {
      handleFile(e.target.files);
    }
  };

  return (
    <div className="admin-csv-upload">
      <form
        id="form-file-upload"
        encType="multipart/form-data"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          name="file"
          type="file"
          id="input-file-upload"
          accept={`.${fileType}`}
          onChange={handleChange}
          disabled={disabled}
          multiple={multiFile}
        />
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={dropping ? 'drag-active' : ''}
        >
          {disabled ? <LockIcon /> : <DragAndDropIcon />}
          <p className="title">
            Drag & Drop or{' '}
            <span className={disabled ? '' : 'choose-active'}>Choose file</span>{' '}
            to upload
          </p>
          <p className="text">
            File should be in {fileType.toUpperCase()} format. Maximum
            {multiFile ? '' : ' 1 File'} up to 10 Mb.
            <br />
            {sampleFileUrl && (
              <span>
                File template must be the same as in downloaded sample.
              </span>
            )}
          </p>
          {uploadError && <p className="error-text">{uploadError}</p>}
        </label>
        {dropping && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </form>
    </div>
  );
};

export default UploadFile;
