import React, {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { format, parse, isValid } from 'date-fns';
import { Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import MessageSearchModeEnums from 'infrastructure/enums/messageSearchModeEnums';
import { isMobile } from 'utils/deviceHelper';
import useOutsideClick from 'utils/useOutsideClick';
import common from 'constants/common';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import { useCloseOpenConversation } from 'infrastructure/hooks/smsDashboard/useCloseOpenConversation';
import {
  getSmsDashboardMessagesKey,
  useSmsDashboardMessages,
} from 'infrastructure/hooks/smsDashboard/useMessages';
import { useMarkConversation } from 'infrastructure/hooks/smsDashboard/useMarkConversation';
import { useQueryClient } from '@tanstack/react-query';
import { INITIAL_STATE } from 'store/smsDashboard';
import { useSmsDashboardUnreadCount } from 'infrastructure/hooks/smsDashboard/useUnreadConversationsCount';
import { PlatformEnums } from 'infrastructure/enums';

import ConversationNameEditModal from './conversationNameEditModal';
import ChatSearchIcon from '../icons/chatSearchIcon';
import ThreeDotsIcon from '../icons/threeDotsIcon';
import XIcon from '../icons/xIcon';
import DateIcon from '../icons/dateIcon';
import Loader from '../Loader';

import type { RootDispatch, RootState } from 'store';
import type {
  IConversation,
  IMessagesListPages,
  MessageSearchModeType,
} from 'infrastructure/interfaces';

const DatePicker = lazy(() => import('antd/es/date-picker'));

interface IChatHeaderProps {
  conversation: IConversation;
}

const ChatHeader = (props: IChatHeaderProps) => {
  const { conversation } = props;

  const ref = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const patientName = conversation?.patient?.fullNameWithOnlyTitle;
  const patientPhone = conversation?.patient?.patientPhone;

  const [inputValue, setInputValue] = useState<string>('');
  const [searchMode, setSearchMode] = useState<MessageSearchModeType>(
    MessageSearchModeEnums.TEXT,
  );
  const [datePickerDate, setDatePickerDate] = useState(null);
  const [isSearchShown, setIsSearchShown] = useState<boolean>(false);
  const [isDatepickerOpen, setIsDatepickerOpen] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState<boolean>(false);

  const device = useSelector((state: RootState) => state.user.deviceType);

  const {
    filters,
    conversationType,
    messagesSearchValue,
    currentConversation,
  } = useSelector((state: RootState) => state.smsDashboard);

  const {
    smsDashboard: { changeCurrentConversation, setMessagesSearchValue },
  } = useDispatch<RootDispatch>();

  const { fetchUnreadConversationsCount } = useSmsDashboardUnreadCount();

  const { markConversation } = useMarkConversation({
    onSuccess() {
      const action = t('smsDashboard.marked_as_unread');
      notificationController.info({
        message: t('smsDashboard.conversation_is_actioned_successfully', {
          action,
        }),
      });
      changeCurrentConversation(INITIAL_STATE.currentConversation);
      fetchUnreadConversationsCount();
    },
  });

  useEffect(() => {
    setInputValue('');
    setIsSearchShown(false);
    setIsDatepickerOpen(false);
    setIsMenuOpen(false);
    setDatePickerDate(null);
  }, [conversation]);

  const debouncedSearch = useCallback(
    debounce(async (mode, conversationData, value) => {
      const date = parse(
        value,
        common.dateFormats.birthDate,
        new Date(),
      ).setDate(
        parse(value, common.dateFormats.birthDate, new Date()).getDate() + 1,
      );

      setMessagesSearchValue({
        conversation: conversationData,
        cursor:
          mode === MessageSearchModeEnums.DATE
            ? format(date, common.dateFormats.dateUTC)
            : undefined,
        searchQuery: mode === MessageSearchModeEnums.TEXT ? value : undefined,
      });
    }, 800),
    [],
  );

  const handleDatePicker = (value: string) => {
    const formattedDate = format(new Date(value), common.dateFormats.birthDate);
    setInputValue(formattedDate);
    debouncedSearch(searchMode, conversation, formattedDate);
  };

  const handleInputChange = (value: string) => {
    const isDate = !!isValid(
      parse(value, common.dateFormats.birthDate, new Date()),
    );
    setInputValue(value);

    if (isDate) {
      setSearchMode(MessageSearchModeEnums.DATE);
      debouncedSearch(
        MessageSearchModeEnums.DATE,
        conversation,
        format(new Date(value), common.dateFormats.birthDate),
      );
    } else {
      setSearchMode(MessageSearchModeEnums.TEXT);
      debouncedSearch(MessageSearchModeEnums.TEXT, conversation, value);
    }
  };

  const clearInput = () => {
    handleInputChange('');
    setSearchMode(MessageSearchModeEnums.TEXT);
    setIsSearchShown(false);
    debouncedSearch(MessageSearchModeEnums.TEXT, conversation, null);
    setDatePickerDate(null);
  };

  useOutsideClick(ref, () => {
    setIsMenuOpen(false);
  });

  const { fetchMessages } = useSmsDashboardMessages({
    conversationId: conversation.id,
    cursor: messagesSearchValue.cursor,
    searchQuery: messagesSearchValue.searchQuery,
  });

  const { closeOpenConversation } = useCloseOpenConversation({
    type: conversationType,
    read: filters.read,
    closed: filters.closed,
    lastMessageDate: filters.lastMessageDate,
    searchQuery: filters.searchQuery,
    onSuccess() {
      fetchMessages();
      const action = conversation.closed === true ? 'opened' : 'closed';
      notificationController.info({
        message: t('smsDashboard.conversation_is_actioned_successfully', {
          action,
        }),
      });
    },
  });
  const messagesKey = getSmsDashboardMessagesKey({
    conversationId: currentConversation.id,
    cursor: undefined,
    searchQuery: undefined,
  });

  const messages = queryClient.getQueryData<IMessagesListPages>(messagesKey);

  const totalMessages = messages?.pages.reduce(
    (acc, page) => acc + page.items.length,
    0,
  );

  const deviceIsMobile = isMobile(device);

  const toggleConversationNameModal = () => {
    setIsRenameModalOpen(!isRenameModalOpen);
  };
  return (
    <div className="chat-header">
      {deviceIsMobile && (
        <button
          type="button"
          aria-label="Previous"
          className="prev"
          data-cy="pagination__prev"
          onClick={() =>
            changeCurrentConversation(INITIAL_STATE.currentConversation)
          }
        />
      )}
      <div className="chat-header-info" hidden={isSearchShown}>
        <div className="chat-header-patient">
          <div className="patient-name">
            {patientName && <span>{patientName} </span>}
            {conversation.name && <span>{conversation.name} </span>}
            <span>{patientPhone}</span>
          </div>
        </div>
        <div className="chat-header-buttons">
          <button
            className="chat-message-search"
            onClick={() => setIsSearchShown(true)}
          >
            <ChatSearchIcon />
          </button>
          <div className="conversations-filters">
            <div className="conversations-sort" ref={ref}>
              <Card
                className={`conversations-sort-button card-border ${
                  isMenuOpen ? 'active' : ''
                }`}
              >
                <CardBody
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className="card-body-sort-arrow"
                >
                  <ThreeDotsIcon />
                </CardBody>
              </Card>
              {isMenuOpen && (
                <div className="conversations-sort-body">
                  {!conversation.closed &&
                    conversation.read === true &&
                    totalMessages !== 0 && (
                      <div
                        className="conversations-sort-item"
                        onClick={() => {
                          markConversation({
                            conversationId: conversation.id,
                            read: false,
                          });
                          setIsMenuOpen(!isMenuOpen);
                        }}
                      >
                        <span>{t(`smsDashboard.mark_as_unread`)}</span>
                      </div>
                    )}
                  <div
                    className="conversations-sort-item"
                    onClick={() => {
                      closeOpenConversation({
                        conversationIds: [conversation.id],
                        close: !conversation.closed,
                      });
                      setIsMenuOpen(!isMenuOpen);
                    }}
                  >
                    <span>
                      {conversation.closed
                        ? t(`smsDashboard.open_conversation`)
                        : t(`smsDashboard.close_conversation`)}
                    </span>
                  </div>
                  {conversation.type === PlatformEnums.UNREGISTERED && (
                    <div
                      className="conversations-sort-item"
                      onClick={() => {
                        toggleConversationNameModal();
                        setIsMenuOpen(!isMenuOpen);
                      }}
                    >
                      {conversation.name === null ||
                      conversation.name === '' ? (
                        <span>{t(`smsDashboard.add_name`)}</span>
                      ) : (
                        <span>{t(`smsDashboard.edit_name`)}</span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="chat-header-search" hidden={!isSearchShown}>
        <div className="chat-search-input">
          <input
            type="text"
            placeholder={t('smsDashboard.search_messages')}
            value={inputValue}
            onChange={(e) => handleInputChange(e.target.value)}
          />
          <span className="chat-search-icon" />
        </div>
        <div className="chat-search-action">
          <button
            className="chat-message-search-date"
            onClick={() => {
              setSearchMode(MessageSearchModeEnums.DATE);
              setIsDatepickerOpen(!isDatepickerOpen);
            }}
          >
            <DateIcon />
          </button>
          <Suspense fallback={<Loader />}>
            <DatePicker
              open={isDatepickerOpen}
              value={datePickerDate}
              onChange={(value: any, dateString: string | string[]) => {
                handleDatePicker(dateString as string);
                setDatePickerDate(value);
              }}
              style={{ visibility: 'hidden', width: 1 }}
              onOpenChange={(isOpen: boolean) => setIsDatepickerOpen(isOpen)}
              placement="bottomRight"
              showToday={false}
            />
          </Suspense>
          <button className="chat-message-search-cancel" onClick={clearInput}>
            <XIcon />
          </button>
        </div>
      </div>
      <ConversationNameEditModal
        isOpen={isRenameModalOpen}
        toggle={toggleConversationNameModal}
        name={conversation.name}
        conversationId={conversation.id}
      />
    </div>
  );
};

export default ChatHeader;
