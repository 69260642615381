import smsDashboard from './smsDashboard.json';
import labels from './labels.json';
import loginStatuses from './login-statuses.json';
import languages from './languages.json';
import controls from './controls.json';
import tabs from './tabs.json';
import genders from './genders.json';
import races from './races.json';
import maritalStatuses from './marital-statuses.json';
import workingStatuses from './working-statuses.json';
import titles from './titles.json';
import rpmStatuses from './rpm-statuses.json';
import ccmStatuses from './ccm-statuses.json';
import numberTypes from './number-types.json';
import contactMethods from './contact-methods.json';
import states from './states.json';
import timezones from './timezones.json';
import validation from './validation.json';
import dates from './dates.json';
import weekMealStatuses from './week-meal-statuses.json';
import confirm from './confirm.json';
import appointmentMethod from './appointment-method.json';
import messages from './messages.json';
import insuranceProviders from './insurance-providers.json';
import insuranceTypes from './insurance-types.json';
import readingTypes from './reading-types.json';
import deviceAlertStatus from './device-alert-status.json';
import pcctNotesGenerator from './pcct-notes-generator.json';
import units from './units.json';
import interactiveTimeDashboard from './interactive-time-dashboard.json';
import ccmAgentDashboard from './ccm-agent-dashboard.json';
import filters from './filters.json';
import errors from './errors.json';
import claimStatuses from './claim-statuses.json';
import clinicReferralTypes from './clinic-referral-types.json';
import providerServiceTypes from './provider-service-types.json';
import serviceDeliveryThresholds from './service-delivery-thresholds.json';
import clinicServiceDeliveryModels from './clinic-service-delivery-models.json';
import clinicRevenueChannels from './clinic-revenue-channels.json';
import clinicStatuses from './clinic-statuses.json';
import deviceAlertChangeStatus from './device-alert-change-status.json';
import ccmStatusReportDashboard from './ccm-status-report-dashboard.json';
import adminTools from './admin-tools.json';
import responseMessages from './response-messages.json';
import consumables from './consumables.json';
import historyLog from './history-log.json';

export const en = {
  translation: {
    adminTools,
    smsDashboard,
    controls,
    labels,
    languages,
    tabs,
    genders,
    races,
    maritalStatuses,
    workingStatuses,
    titles,
    rpmStatuses,
    ccmStatuses,
    numberTypes,
    contactMethods,
    states,
    timezones,
    validation,
    dates,
    weekMealStatuses,
    confirm,
    appointmentMethod,
    messages,
    insuranceProviders,
    insuranceTypes,
    readingTypes,
    deviceAlertStatus,
    pcctNotesGenerator,
    units,
    interactiveTimeDashboard,
    filters,
    claimStatuses,
    clinicReferralTypes,
    providerServiceTypes,
    serviceDeliveryThresholds,
    clinicServiceDeliveryModels,
    clinicRevenueChannels,
    clinicStatuses,
    deviceAlertChangeStatus,
    ccmAgentDashboard,
    ccmStatusReportDashboard,
    errors,
    responseMessages,
    consumables,
    historyLog,
    loginStatuses,
  },
};
