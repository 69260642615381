import React from 'react';
import PropTypes from 'prop-types';

import FormInput from './FormInput';
import FormGroup from './FormGroup';
import { getShowFromDepends } from '../helpers';

const WizardSection = ({ fields, title, errors, values }) => (
  <>
    <div>{title}</div>
    {Object.keys(fields).map((key) => {
      const params = fields[key];
      const error = errors[key];
      const isShowFromDepends = getShowFromDepends(params, values);
      if (!isShowFromDepends) {
        return null;
      }
      if (params.type === 'group') {
        return (
          <FormGroup
            id={key}
            key={key}
            {...params}
            errors={error}
            values={values}
          />
        );
      }
      return (
        <FormInput
          values={values}
          key={key}
          type={params.type}
          params={params}
          error={error}
        />
      );
    })}
  </>
);
WizardSection.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ),
  values: PropTypes.shape({}),
  errors: PropTypes.shape({}),
};
export default WizardSection;
