import Button from 'components/atoms/button';
import { formSections } from 'constants/ccmClinicalNotesForms';
import PropTypes from 'prop-types';
import useCcmClinicalNotesModal from 'utils/useCcmClinicalNotesModal';
import DraggableModal from 'components/organisms/draggableModal/draggableModal';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import Section from 'components/molecules/section';

const CcmClinicalNotesModal = ({
  onSubmit,
  closeModal,
  isOpen,
  patientGuid,
}) => {
  const {
    values,
    currentStep,
    errorSections,
    canEditPage,
    isLoading,
    setValue,
    goToPrevStep,
    goToNextStep,
    submit,
  } = useCcmClinicalNotesModal({
    onSubmit,
    closeModal,
    patientGuid,
  });

  return (
    <DraggableModal
      isOpen={isOpen}
      toggle={closeModal}
      title={`${formSections[currentStep].title} - ${currentStep + 1} of ${
        formSections.length
      }`}
      styleName="popup narrative-popup"
    >
      <BaseSpinWrapper spinning={isLoading}>
        <Section
          forms={formSections[currentStep].forms}
          setValue={setValue}
          values={values}
          canEditPage={canEditPage}
        />
        <div className="buttons-wrapper">
          {currentStep !== 0 && (
            <Button
              handleClick={goToPrevStep}
              text="< Back"
              fillBg={false}
              dataCy="back-btn"
            />
          )}
          {currentStep === formSections.length - 1 ? (
            <>
              {errorSections.length > 0 && (
                <p className="error-fields" data-cy="modal-error">
                  Missing fields in section(s): {errorSections.join(', ')}{' '}
                </p>
              )}
              <Button
                disabled={errorSections.length}
                handleClick={submit}
                text="Submit"
              />
            </>
          ) : (
            <Button
              handleClick={goToNextStep}
              text="Forward >"
              data-cy="forward-btn"
            />
          )}
        </div>
      </BaseSpinWrapper>
    </DraggableModal>
  );
};

CcmClinicalNotesModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  patientGuid: PropTypes.string,
};

export default CcmClinicalNotesModal;
