import i18next from 'i18next';

type Label = string | undefined | null;

export interface IBooleanItem {
  id: boolean;
  name: string;
}

export const booleansDataSource = (
  trueLabel: Label,
  falseLabel: Label,
): IBooleanItem[] => {
  return [
    {
      id: true,
      name: trueLabel ?? i18next.t('controls.true'),
    },
    {
      id: false,
      name: falseLabel ?? i18next.t('controls.false'),
    },
  ];
};

export const optedDataSource = (): IBooleanItem[] => {
  return [
    {
      id: true,
      name: i18next.t('controls.optedIn'),
    },
    {
      id: false,
      name: i18next.t('controls.optedOut'),
    },
  ];
};
