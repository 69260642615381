import { useMemo, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import BaseSelect from 'components/molecules/select/base';
import i18next from 'i18next';
import { CcmStatuses, RpmStatuses } from 'infrastructure/enums';

import type { Filters } from 'infrastructure/hooks/interactiveTimeDashboard/types';
import type { Option } from 'components/molecules/select/base';

interface Props {
  filters: Filters;
  showSelectAll?: boolean;
  onChange: (e: Option[]) => void;
}

type PatientStatusValue = 'both' | 'rpm';

const patientStatusesOptions = [
  { label: i18next.t('labels.all'), value: 'All' },
  { label: i18next.t('labels.activeAndEnrolled'), value: 'both' },
  { label: i18next.t('labels.activeAndNotEnrolled'), value: 'rpm' },
];
const baseOptionsMapper: Record<PatientStatusValue, Option[]> = {
  rpm: [
    { label: i18next.t('labels.active'), value: RpmStatuses.Active },
    { label: i18next.t('labels.notEnrolled'), value: CcmStatuses.NotEnrolled },
  ],
  both: [
    { label: i18next.t('labels.active'), value: RpmStatuses.Active },
    { label: i18next.t('labels.enrolled'), value: CcmStatuses.Enrolled },
  ],
};

const getSelectedStatus = (filters: Filters): Option | undefined => {
  if (filters.status && filters.ccmStatus) {
    return filters.ccmStatus.value === CcmStatuses.Enrolled
      ? patientStatusesOptions.find((el) => el.value === 'both')
      : patientStatusesOptions.find((el) => el.value === 'rpm');
  }
  return patientStatusesOptions.find((el) => el.value === 'All');
};

const PatientStatuses: FC<Props> = ({ onChange, filters, showSelectAll }) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return patientStatusesOptions.map((item) => ({
      label: item.label,
      value: item.value,
      title: item.label,
    }));
  }, []);

  const selectedStatuses = useMemo(() => getSelectedStatus(filters), [filters]);

  const handleChange = (newValue: Option | Option[] | null) => {
    if (
      newValue &&
      !Array.isArray(newValue) &&
      newValue.value in baseOptionsMapper
    ) {
      onChange(baseOptionsMapper[newValue.value as PatientStatusValue]);
    } else {
      onChange([]);
    }
  };

  return (
    <BaseSelect
      label={t('labels.patientStatuses')}
      placeholder={t('labels.all')}
      showSelectAll={showSelectAll}
      options={options}
      value={selectedStatuses}
      onChange={handleChange}
    />
  );
};

export default PatientStatuses;
