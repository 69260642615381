import { useClinicLogs } from 'infrastructure/hooks/clinic/use-clinic-logs';
import { useUpdateEffect } from 'react-use';
import BaseTableNew from 'components/atoms/base-table/new';
import { useTranslation } from 'react-i18next';
import BaseRangeDatepicker from 'components/atoms/base-range-datepicker';
import { useState, type FC } from 'react';
import { endOfDate, startOfDate } from 'infrastructure/functions';

import { clinicLogColumns } from './cols';
import s from './styles.module.scss';

import type { DateRange } from 'infrastructure/types';

interface IClinicLogProps {
  clinicGuid: string;
}

const ClinicLog: FC<IClinicLogProps> = (props) => {
  const { clinicGuid } = props;
  const [dateRange, setDateRange] = useState<[Date, Date] | undefined>();

  const { loading, meta, items, setMeta } = useClinicLogs({
    clinicGuid,
  });

  const { t } = useTranslation();

  const onChangePage = (page: number) => {
    setMeta({
      ...meta,
      page,
      dateRange,
    });
  };

  useUpdateEffect(() => {
    setMeta({
      ...meta,
      page: 1,
      dateRange,
    });
  }, [dateRange]);

  const changeTimeRange = async (range?: DateRange) => {
    let parsedRange: [Date, Date] | undefined;
    if (range) {
      parsedRange = [startOfDate(range[0]), endOfDate(range[1])];
    }
    setDateRange(parsedRange);
  };

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <h3 className={s.title}>{t('labels.historyLog')}</h3>
        <div className={s.controls}>
          <BaseRangeDatepicker
            withPresets
            onChange={(dates) => {
              changeTimeRange(dates);
            }}
          />
        </div>
      </div>

      <BaseTableNew
        dataCy="clinic-log-table"
        rowKey="key"
        columns={clinicLogColumns()}
        items={items}
        loading={loading}
        pagination={{
          current: meta.page,
          pageSize: meta.items,
          total: meta.totalCount,
        }}
        onChangePage={onChangePage}
        bordered
      />
    </div>
  );
};

export default ClinicLog;
